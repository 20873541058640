import { postRecordEvents } from '@/views/checkout/api'

/**
 *  获取订单 ID
 * @returns {string}
 */
const getOrderId = () => {
  const url = window.location.pathname
  return url.substring(url.lastIndexOf('/') + 1, url.length)
}
/**
 * 收集用户操作的对象
 */
const getOperationObject = () => {
  let obj = null
  try {
    obj = JSON.parse(window.sessionStorage.getItem(getOrderId()))
  } catch (e) {
    console.error(e)
  }
  obj = obj || {
    startTime: '',
    completedTime: '',
    closeTime: '',
    submitTime: '',
    flow: []
  }
  return obj
}

/**
 * 收集用户操作
 */
export const saveOperation = (operation) => {
  const obj = getOperationObject()
  // eslint-disable-next-line no-unused-expressions
  obj?.flow?.push(operation)
  window.sessionStorage.setItem(getOrderId(), JSON.stringify(obj))
}

/**
 * 收集用户操作
 */
export const saveExecuteTime = (key, time) => {
  const obj = getOperationObject()
  obj[key || 'error'] = time || new Date().getTime() + ''
  window.sessionStorage.setItem(getOrderId(), JSON.stringify(obj))
}

/**
 * 用户操作请求
 */
const httpAddExecuteTime = () => {
  const params = { ...getOperationObject(), orderId: getOrderId() }
  const formData = new FormData()
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      formData.append(key, JSON.stringify(params[key]))
    } else {
      formData.append(key, params[key])
    }
  })
  postRecordEvents(formData)
}

/**
 * 用户操作提交
 */
export const submitOperationData = () => {
  saveExecuteTime('closeTime')
  httpAddExecuteTime()
}
