<template>
  <div id="lang">
    <a-select v-model="app.currentLan" @change="changeLan" class="lang-wrapper">
      <a-select-option v-for="(item, idx) in lang" :value="item.value" :key="idx">{{ item.label }}</a-select-option>
    </a-select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '@/i18n'
import { Languages } from '@/enums/language'

export default {
  // inject: ['reload'],
  computed: {
    ...mapGetters(['app'])
  },
  data() {
    return {
      lang: Languages
    }
  },
  created() {
    loadLanguageAsync(this.app.currentLan)
  },
  methods: {
    changeLan(lang) {
      loadLanguageAsync(lang)
    }
  }
}
</script>
<style lang="less">
.lang-wrapper {
  width: 120px;

  input {
    font-weight: bold;
  }
}
</style>
