<template>
  <div class="card-number">
    <a-input size="large"
             inputmode="numeric"
             v-model="valueInner"
             :allow-clear="allowClear"
             :maxLength="29"
             @input.native="inputHandler"
             @focus="focusHandler"
             @blur="blurHandler">
      <template #suffix>
        <div class="cards">
          <div class="card-logo">
            <img :src="cardImg"/>
          </div>
          <!-- <div class="card-logo" v-show="current === -1">
            <img :src="`data:image/png;base64,${cards[0].logo}`" />
          </div>
          <div v-show="current === cardIndex" class="card-logo" v-for="(card, cardIndex) in cards" :key="cardIndex">
            <img :src="`data:image/png;base64,${card.logo}`" />
          </div> -->
        </div>
      </template>
    </a-input>
  </div>
</template>
<script>
import { onCardInput } from '@/utils/tools/checkout';

export default {
  name: 'cardnumber',
  props: {
    value: {
      type: String,
      default: ''
    },
    cards: {
      type: Array,
      default: () => []
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: 0,
      valueInner: this.value,
      cardImg: require('@/assets/images/checkout/card.png')
    };
  },
  methods: {
    inputHandler(evt) {
      onCardInput(evt, (value, card) => {
        this.cardImg = !card ? require('@/assets/images/checkout/card.png') : card.logo;
        this.valueInner = value;
        this.$emit('update:value', this.valueInner);
        this.$emit('cardName', card?.cardType);
      });
    },
    focusHandler(evt) {
      this.$emit('focus', evt);
    },
    blurHandler(evt) {
      this.$emit('blur', evt);
    }
  },
  watch: {
    value: {
      handler(curValue) {
        this.valueInner = curValue;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.card-number {
  .cards {
    color: red;
    display: flex;

    .card-logo {
      display: flex;
      height: 20px;
      transition: all 0.3s ease-in;

      img {
        height: 100%;
      }
    }
  }
}
</style>
