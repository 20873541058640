import http from '@/utils/http/index'

// 订单信息
export function getOrderInfo(id) {
  return http.get(`/checkout/information/${id}`)
}

// 确认支付
export function confirm(id, data) {
  return http.post(`/checkout/confirm/${id}`, data)
}

// 保存操作信息
export function postRecordEvents(data) {
  const url =
    process.env.NODE_ENV === 'development' ? '/webhook/merchant/cashier/record/add' : '/webhook/api/merchant/cashier/record/add'
  navigator.sendBeacon(url, data)
  sessionStorage.clear()
}

// Test
export function postTestRecordEvents() {
  http.get('/webhook/merchant/cashier/record/get').then((res) => {
    console.log(res)
  })
}

// 订单状态
export function getStateList(country2) {
  return http.get('/checkout/state', { params: { country2 } })
}

// 订单状态
export function getOrderStatus(id) {
  return http.get(`/checkout/result/${id}`)
}
