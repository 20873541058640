export default [
  {
    code: 'AF',
    emoji: '🇦🇫',
    translations: {
      de: 'Afghanistan',
      ru: 'Афганистан',
      pt: 'Afeganistão',
      'pt-BR': 'Afeganistão',
      kr: '아프가니스탄',
      hr: 'Afganistan',
      it: 'Afghanistan',
      fr: 'Afghanistan',
      'zh-cn': '阿富汗',
      es: 'Afganistán',
      uk: 'Афганістан',
      ja: 'アフガニスタン',
      fa: 'افغانستان',
      pl: 'Afganistan',
      nl: 'Afghanistan',
      tr: 'Afganistan'
    },
    name: 'Afghanistan',
    phone_code: '93'
  },
  {
    code: 'AX',
    emoji: '🇦🇽',
    translations: {
      de: 'Åland',
      ru: 'Аландские острова',
      pt: 'Ilhas de Aland',
      'pt-BR': 'Ilhas de Aland',
      kr: '올란드 제도',
      hr: 'Ålandski otoci',
      it: 'Isole Aland',
      fr: 'Åland',
      'zh-cn': '奥兰群岛',
      es: 'Alandia',
      uk: 'Аландські острови',
      ja: 'オーランド諸島',
      fa: 'جزایر الند',
      pl: 'Wyspy Alandzkie',
      nl: 'Ålandeilanden',
      tr: 'Åland Adalari'
    },
    name: 'Aland Islands',
    phone_code: '358'
  },
  {
    code: 'AL',
    emoji: '🇦🇱',
    translations: {
      de: 'Albanien',
      ru: 'Албания',
      pt: 'Albânia',
      'pt-BR': 'Albânia',
      kr: '알바니아',
      hr: 'Albanija',
      it: 'Albania',
      fr: 'Albanie',
      'zh-cn': '阿尔巴尼亚',
      es: 'Albania',
      uk: 'Албанія',
      ja: 'アルバニア',
      fa: 'آلبانی',
      pl: 'Albania',
      nl: 'Albanië',
      tr: 'Arnavutluk'
    },
    name: 'Albania',
    phone_code: '355'
  },
  {
    code: 'DZ',
    emoji: '🇩🇿',
    translations: {
      de: 'Algerien',
      ru: 'Алжир',
      pt: 'Argélia',
      'pt-BR': 'Argélia',
      kr: '알제리',
      hr: 'Alžir',
      it: 'Algeria',
      fr: 'Algérie',
      'zh-cn': '阿尔及利亚',
      es: 'Argelia',
      uk: 'Алжир',
      ja: 'アルジェリア',
      fa: 'الجزایر',
      pl: 'Algieria',
      nl: 'Algerije',
      tr: 'Cezayir'
    },
    name: 'Algeria',
    phone_code: '213'
  },
  {
    code: 'AS',
    emoji: '🇦🇸',
    translations: {
      de: 'Amerikanisch-Samoa',
      ru: 'Американское Самоа',
      pt: 'Samoa Americana',
      'pt-BR': 'Samoa Americana',
      kr: '아메리칸사모아',
      hr: 'Američka Samoa',
      it: 'Samoa Americane',
      fr: 'Samoa américaines',
      'zh-cn': '美属萨摩亚',
      es: 'Samoa Americana',
      uk: 'Американське Самоа',
      ja: 'アメリカ領サモア',
      fa: 'ساموآی آمریکا',
      pl: 'Samoa Amerykańskie',
      nl: 'Amerikaans Samoa',
      tr: 'Amerikan Samoasi'
    },
    name: 'American Samoa',
    phone_code: '1'
  },
  {
    code: 'AD',
    emoji: '🇦🇩',
    translations: {
      de: 'Andorra',
      ru: 'Андорра',
      pt: 'Andorra',
      'pt-BR': 'Andorra',
      kr: '안도라',
      hr: 'Andora',
      it: 'Andorra',
      fr: 'Andorre',
      'zh-cn': '安道尔',
      es: 'Andorra',
      uk: 'Андорра',
      ja: 'アンドラ',
      fa: 'آندورا',
      pl: 'Andora',
      nl: 'Andorra',
      tr: 'Andorra'
    },
    name: 'Andorra',
    phone_code: '376'
  },
  {
    code: 'AO',
    emoji: '🇦🇴',
    translations: {
      de: 'Angola',
      ru: 'Ангола',
      pt: 'Angola',
      'pt-BR': 'Angola',
      kr: '앙골라',
      hr: 'Angola',
      it: 'Angola',
      fr: 'Angola',
      'zh-cn': '安哥拉',
      es: 'Angola',
      uk: 'Ангола',
      ja: 'アンゴラ',
      fa: 'آنگولا',
      pl: 'Angola',
      nl: 'Angola',
      tr: 'Angola'
    },
    name: 'Angola',
    phone_code: '244'
  },
  {
    code: 'AI',
    emoji: '🇦🇮',
    translations: {
      de: 'Anguilla',
      ru: 'Ангилья',
      pt: 'Anguila',
      'pt-BR': 'Anguila',
      kr: '앵귈라',
      hr: 'Angvila',
      it: 'Anguilla',
      fr: 'Anguilla',
      'zh-cn': '安圭拉',
      es: 'Anguilla',
      uk: 'Ангілья',
      ja: 'アンギラ',
      fa: 'آنگویلا',
      pl: 'Anguilla',
      nl: 'Anguilla',
      tr: 'Anguilla'
    },
    name: 'Anguilla',
    phone_code: '1'
  },
  {
    code: 'AQ',
    emoji: '🇦🇶',
    translations: {
      de: 'Antarktika',
      ru: 'Антарктида',
      pt: 'Antárctida',
      'pt-BR': 'Antártida',
      kr: '남극',
      hr: 'Antarktika',
      it: 'Antartide',
      fr: 'Antarctique',
      'zh-cn': '南极洲',
      es: 'Antártida',
      uk: 'Антарктида',
      ja: '南極大陸',
      fa: 'جنوبگان',
      pl: 'Antarktyda',
      nl: 'Antarctica',
      tr: 'Antartika'
    },
    name: 'Antarctica',
    phone_code: '672'
  },
  {
    code: 'AG',
    emoji: '🇦🇬',
    translations: {
      de: 'Antigua und Barbuda',
      ru: 'Антигуа и Барбуда',
      pt: 'Antígua e Barbuda',
      'pt-BR': 'Antígua e Barbuda',
      kr: '앤티가 바부다',
      hr: 'Antigva i Barbuda',
      it: 'Antigua e Barbuda',
      fr: 'Antigua-et-Barbuda',
      'zh-cn': '安提瓜和巴布达',
      es: 'Antigua y Barbuda',
      uk: 'Антигуа і Барбуда',
      ja: 'アンティグア・バーブーダ',
      fa: 'آنتیگوا و باربودا',
      pl: 'Antigua i Barbuda',
      nl: 'Antigua en Barbuda',
      tr: 'Antigua Ve Barbuda'
    },
    name: 'Antigua and Barbuda',
    phone_code: '1'
  },
  {
    code: 'AR',
    emoji: '🇦🇷',
    translations: {
      de: 'Argentinien',
      ru: 'Аргентина',
      pt: 'Argentina',
      'pt-BR': 'Argentina',
      kr: '아르헨티나',
      hr: 'Argentina',
      it: 'Argentina',
      fr: 'Argentine',
      'zh-cn': '阿根廷',
      es: 'Argentina',
      uk: 'Аргентина',
      ja: 'アルゼンチン',
      fa: 'آرژانتین',
      pl: 'Argentyna',
      nl: 'Argentinië',
      tr: 'Arjantin'
    },
    name: 'Argentina',
    phone_code: '54'
  },
  {
    code: 'AM',
    emoji: '🇦🇲',
    translations: {
      de: 'Armenien',
      ru: 'Армения',
      pt: 'Arménia',
      'pt-BR': 'Armênia',
      kr: '아르메니아',
      hr: 'Armenija',
      it: 'Armenia',
      fr: 'Arménie',
      'zh-cn': '亚美尼亚',
      es: 'Armenia',
      uk: 'Вірменія',
      ja: 'アルメニア',
      fa: 'ارمنستان',
      pl: 'Armenia',
      nl: 'Armenië',
      tr: 'Ermenistan'
    },
    name: 'Armenia',
    phone_code: '374'
  },
  {
    code: 'AW',
    emoji: '🇦🇼',
    translations: {
      de: 'Aruba',
      ru: 'Аруба',
      pt: 'Aruba',
      'pt-BR': 'Aruba',
      kr: '아루바',
      hr: 'Aruba',
      it: 'Aruba',
      fr: 'Aruba',
      'zh-cn': '阿鲁巴',
      es: 'Aruba',
      uk: 'Аруба',
      ja: 'アルバ',
      fa: 'آروبا',
      pl: 'Aruba',
      nl: 'Aruba',
      tr: 'Aruba'
    },
    name: 'Aruba',
    phone_code: '297'
  },
  {
    code: 'AU',
    emoji: '🇦🇺',
    translations: {
      de: 'Australien',
      ru: 'Австралия',
      pt: 'Austrália',
      'pt-BR': 'Austrália',
      kr: '호주',
      hr: 'Australija',
      it: 'Australia',
      fr: 'Australie',
      'zh-cn': '澳大利亚',
      es: 'Australia',
      uk: 'Австралія',
      ja: 'オーストラリア',
      fa: 'استرالیا',
      pl: 'Australia',
      nl: 'Australië',
      tr: 'Avustralya'
    },
    name: 'Australia',
    phone_code: '61'
  },
  {
    code: 'AT',
    emoji: '🇦🇹',
    translations: {
      de: 'Österreich',
      ru: 'Австрия',
      pt: 'áustria',
      'pt-BR': 'áustria',
      kr: '오스트리아',
      hr: 'Austrija',
      it: 'Austria',
      fr: 'Autriche',
      'zh-cn': '奥地利',
      es: 'Austria',
      uk: 'Австрія',
      ja: 'オーストリア',
      fa: 'اتریش',
      pl: 'Austria',
      nl: 'Oostenrijk',
      tr: 'Avusturya'
    },
    name: 'Austria',
    phone_code: '43'
  },
  {
    code: 'AZ',
    emoji: '🇦🇿',
    translations: {
      de: 'Aserbaidschan',
      ru: 'Азербайджан',
      pt: 'Azerbaijão',
      'pt-BR': 'Azerbaijão',
      kr: '아제르바이잔',
      hr: 'Azerbajdžan',
      it: 'Azerbaijan',
      fr: 'Azerbaïdjan',
      'zh-cn': '阿塞拜疆',
      es: 'Azerbaiyán',
      uk: 'Азербайджан',
      ja: 'アゼルバイジャン',
      fa: 'آذربایجان',
      pl: 'Azerbejdżan',
      nl: 'Azerbeidzjan',
      tr: 'Azerbaycan'
    },
    name: 'Azerbaijan',
    phone_code: '994'
  },
  {
    code: 'BH',
    emoji: '🇧🇭',
    translations: {
      de: 'Bahrain',
      ru: 'Бахрейн',
      pt: 'Barém',
      'pt-BR': 'Bahrein',
      kr: '바레인',
      hr: 'Bahrein',
      it: 'Bahrein',
      fr: 'Bahreïn',
      'zh-cn': '巴林',
      es: 'Bahrein',
      uk: 'Бахрейн',
      ja: 'バーレーン',
      fa: 'بحرین',
      pl: 'Bahrajn',
      nl: 'Bahrein',
      tr: 'Bahreyn'
    },
    name: 'Bahrain',
    phone_code: '973'
  },
  {
    code: 'BD',
    emoji: '🇧🇩',
    translations: {
      de: 'Bangladesch',
      ru: 'Бангладеш',
      pt: 'Bangladeche',
      'pt-BR': 'Bangladesh',
      kr: '방글라데시',
      hr: 'Bangladeš',
      it: 'Bangladesh',
      fr: 'Bangladesh',
      'zh-cn': '孟加拉',
      es: 'Bangladesh',
      uk: 'Бангладеш',
      ja: 'バングラデシュ',
      fa: 'بنگلادش',
      pl: 'Bangladesz',
      nl: 'Bangladesh',
      tr: 'Bangladeş'
    },
    name: 'Bangladesh',
    phone_code: '880'
  },
  {
    code: 'BB',
    emoji: '🇧🇧',
    translations: {
      de: 'Barbados',
      ru: 'Барбадос',
      pt: 'Barbados',
      'pt-BR': 'Barbados',
      kr: '바베이도스',
      hr: 'Barbados',
      it: 'Barbados',
      fr: 'Barbade',
      'zh-cn': '巴巴多斯',
      es: 'Barbados',
      uk: 'Барбадос',
      ja: 'バルバドス',
      fa: 'باربادوس',
      pl: 'Barbados',
      nl: 'Barbados',
      tr: 'Barbados'
    },
    name: 'Barbados',
    phone_code: '1'
  },
  {
    code: 'BY',
    emoji: '🇧🇾',
    translations: {
      de: 'Weißrussland',
      ru: 'Беларусь',
      pt: 'Bielorrússia',
      'pt-BR': 'Bielorrússia',
      kr: '벨라루스',
      hr: 'Bjelorusija',
      it: 'Bielorussia',
      fr: 'Biélorussie',
      'zh-cn': '白俄罗斯',
      es: 'Bielorrusia',
      uk: 'Білорусь',
      ja: 'ベラルーシ',
      fa: 'بلاروس',
      pl: 'Białoruś',
      nl: 'Wit-Rusland',
      tr: 'Belarus'
    },
    name: 'Belarus',
    phone_code: '375'
  },
  {
    code: 'BE',
    emoji: '🇧🇪',
    translations: {
      de: 'Belgien',
      ru: 'Бельгия',
      pt: 'Bélgica',
      'pt-BR': 'Bélgica',
      kr: '벨기에',
      hr: 'Belgija',
      it: 'Belgio',
      fr: 'Belgique',
      'zh-cn': '比利时',
      es: 'Bélgica',
      uk: 'Бельгія',
      ja: 'ベルギー',
      fa: 'بلژیک',
      pl: 'Belgia',
      nl: 'België',
      tr: 'Belçika'
    },
    name: 'Belgium',
    phone_code: '32'
  },
  {
    code: 'BZ',
    emoji: '🇧🇿',
    translations: {
      de: 'Belize',
      ru: 'Белиз',
      pt: 'Belize',
      'pt-BR': 'Belize',
      kr: '벨리즈',
      hr: 'Belize',
      it: 'Belize',
      fr: 'Belize',
      'zh-cn': '伯利兹',
      es: 'Belice',
      uk: 'Беліз',
      ja: 'ベリーズ',
      fa: 'بلیز',
      pl: 'Belize',
      nl: 'Belize',
      tr: 'Belize'
    },
    name: 'Belize',
    phone_code: '501'
  },
  {
    code: 'BJ',
    emoji: '🇧🇯',
    translations: {
      de: 'Benin',
      ru: 'Бенин',
      pt: 'Benim',
      'pt-BR': 'Benin',
      kr: '베냉',
      hr: 'Benin',
      it: 'Benin',
      fr: 'Bénin',
      'zh-cn': '贝宁',
      es: 'Benín',
      uk: 'Бенін',
      ja: 'ベナン',
      fa: 'بنین',
      pl: 'Benin',
      nl: 'Benin',
      tr: 'Benin'
    },
    name: 'Benin',
    phone_code: '229'
  },
  {
    code: 'BM',
    emoji: '🇧🇲',
    translations: {
      de: 'Bermuda',
      ru: 'Бермуды',
      pt: 'Bermudas',
      'pt-BR': 'Bermudas',
      kr: '버뮤다',
      hr: 'Bermudi',
      it: 'Bermuda',
      fr: 'Bermudes',
      'zh-cn': '百慕大',
      es: 'Bermudas',
      uk: 'Бермудські острови',
      ja: 'バミューダ',
      fa: 'برمودا',
      pl: 'Bermudy',
      nl: 'Bermuda',
      tr: 'Bermuda'
    },
    name: 'Bermuda',
    phone_code: '1'
  },
  {
    code: 'BT',
    emoji: '🇧🇹',
    translations: {
      de: 'Bhutan',
      ru: 'Бутан',
      pt: 'Butão',
      'pt-BR': 'Butão',
      kr: '부탄',
      hr: 'Butan',
      it: 'Bhutan',
      fr: 'Bhoutan',
      'zh-cn': '不丹',
      es: 'Bután',
      uk: 'Бутан',
      ja: 'ブータン',
      fa: 'بوتان',
      pl: 'Bhutan',
      nl: 'Bhutan',
      tr: 'Butan'
    },
    name: 'Bhutan',
    phone_code: '975'
  },
  {
    code: 'BO',
    emoji: '🇧🇴',
    translations: {
      de: 'Bolivien',
      ru: 'Боливия',
      pt: 'Bolívia',
      'pt-BR': 'Bolívia',
      kr: '볼리비아',
      hr: 'Bolivija',
      it: 'Bolivia',
      fr: 'Bolivie',
      'zh-cn': '玻利维亚',
      es: 'Bolivia',
      uk: 'Болівія',
      ja: 'ボリビア多民族国',
      fa: 'بولیوی',
      pl: 'Boliwia',
      nl: 'Bolivia',
      tr: 'Bolivya'
    },
    name: 'Bolivia',
    phone_code: '591'
  },
  {
    code: 'BQ',
    emoji: '🇧🇶',
    translations: {
      de: 'Bonaire, Sint Eustatius und Saba',
      ru: 'Бонайре, Синт-Эстатиус и Саба',
      pt: 'Bonaire',
      'pt-BR': 'Bonaire',
      uk: 'Бонайре, Сент-Естатіус і Саба',
      kr: '보네르 섬',
      fa: 'بونیر',
      it: 'Bonaire, Saint-Eustache e Saba',
      fr: 'Bonaire, Saint-Eustache et Saba',
      pl: 'Bonaire, Sint Eustatius i Saba',
      'zh-cn': '博内尔岛、圣尤斯特歇斯和萨巴岛',
      tr: 'Karayip Hollandasi'
    },
    name: 'Bonaire, Sint Eustatius and Saba',
    phone_code: '599'
  },
  {
    code: 'BA',
    emoji: '🇧🇦',
    translations: {
      de: 'Bosnien und Herzegowina',
      ru: 'Босния и Герцеговина',
      pt: 'Bósnia e Herzegovina',
      'pt-BR': 'Bósnia e Herzegovina',
      kr: '보스니아 헤르체고비나',
      hr: 'Bosna i Hercegovina',
      it: 'Bosnia ed Erzegovina',
      fr: 'Bosnie-Herzégovine',
      'zh-cn': '波斯尼亚和黑塞哥维那',
      es: 'Bosnia y Herzegovina',
      uk: 'Боснія і Герцеговина',
      ja: 'ボスニア・ヘルツェゴビナ',
      fa: 'بوسنی و هرزگوین',
      pl: 'Bośnia i Hercegowina',
      nl: 'Bosnië en Herzegovina',
      tr: 'Bosna Hersek'
    },
    name: 'Bosnia and Herzegovina',
    phone_code: '387'
  },
  {
    code: 'BW',
    emoji: '🇧🇼',
    translations: {
      de: 'Botswana',
      ru: 'Ботсвана',
      pt: 'Botsuana',
      'pt-BR': 'Botsuana',
      kr: '보츠와나',
      hr: 'Bocvana',
      it: 'Botswana',
      fr: 'Botswana',
      'zh-cn': '博茨瓦纳',
      es: 'Botswana',
      uk: 'Ботсвана',
      ja: 'ボツワナ',
      fa: 'بوتسوانا',
      pl: 'Botswana',
      nl: 'Botswana',
      tr: 'Botsvana'
    },
    name: 'Botswana',
    phone_code: '267'
  },
  {
    code: 'BV',
    emoji: '🇧🇻',
    translations: {
      de: 'Bouvetinsel',
      ru: 'Остров Буве',
      pt: 'Ilha Bouvet',
      'pt-BR': 'Ilha Bouvet',
      kr: '부벳 섬',
      hr: 'Otok Bouvet',
      it: 'Isola Bouvet',
      fr: 'Île Bouvet',
      'zh-cn': '布维岛',
      es: 'Isla Bouvet',
      uk: 'Острів Буве',
      ja: 'ブーベ島',
      fa: 'جزیره بووه',
      pl: 'Wyspa Bouveta',
      nl: 'Bouveteiland',
      tr: 'Bouvet Adasi'
    },
    name: 'Bouvet Island',
    phone_code: '0055'
  },
  {
    code: 'BR',
    emoji: '🇧🇷',
    translations: {
      de: 'Brasilien',
      ru: 'Бразилия',
      pt: 'Brasil',
      'pt-BR': 'Brasil',
      kr: '브라질',
      hr: 'Brazil',
      it: 'Brasile',
      fr: 'Brésil',
      'zh-cn': '巴西',
      es: 'Brasil',
      uk: 'Бразилія',
      ja: 'ブラジル',
      fa: 'برزیل',
      pl: 'Brazylia',
      nl: 'Brazilië',
      tr: 'Brezilya'
    },
    name: 'Brazil',
    phone_code: '55'
  },
  {
    code: 'IO',
    emoji: '🇮🇴',
    translations: {
      de: 'Britisches Territorium im Indischen Ozean',
      ru: 'Британская территория в Индийском океане',
      pt: 'Território Britânico do Oceano Índico',
      'pt-BR': 'Território Britânico do Oceano íÍdico',
      kr: '영국령 인도양 지역',
      hr: 'Britanski Indijskooceanski teritorij',
      it: "Territorio britannico dell'oceano indiano",
      fr: "Territoire britannique de l'océan Indien",
      'zh-cn': '英属印度洋领地',
      es: 'Territorio Británico del Océano Índico',
      uk: 'Британська територія в Індійському океані',
      ja: 'イギリス領インド洋地域',
      fa: 'قلمرو بریتانیا در اقیانوس هند',
      pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      nl: 'Britse Gebieden in de Indische Oceaan',
      tr: 'Britanya Hint Okyanusu Topraklari'
    },
    name: 'British Indian Ocean Territory',
    phone_code: '246'
  },
  {
    code: 'BN',
    emoji: '🇧🇳',
    translations: {
      de: 'Brunei',
      ru: 'Бруней',
      pt: 'Brunei',
      'pt-BR': 'Brunei',
      kr: '브루나이',
      hr: 'Brunej',
      it: 'Brunei',
      fr: 'Brunei',
      'zh-cn': '文莱',
      es: 'Brunei',
      uk: 'Бруней',
      ja: 'ブルネイ・ダルサラーム',
      fa: 'برونئی',
      pl: 'Brunei',
      nl: 'Brunei',
      tr: 'Brunei'
    },
    name: 'Brunei',
    phone_code: '673'
  },
  {
    code: 'BG',
    emoji: '🇧🇬',
    translations: {
      de: 'Bulgarien',
      ru: 'Болгария',
      pt: 'Bulgária',
      'pt-BR': 'Bulgária',
      kr: '불가리아',
      hr: 'Bugarska',
      it: 'Bulgaria',
      fr: 'Bulgarie',
      'zh-cn': '保加利亚',
      es: 'Bulgaria',
      uk: 'Болгарія',
      ja: 'ブルガリア',
      fa: 'بلغارستان',
      pl: 'Bułgaria',
      nl: 'Bulgarije',
      tr: 'Bulgaristan'
    },
    name: 'Bulgaria',
    phone_code: '359'
  },
  {
    code: 'BF',
    emoji: '🇧🇫',
    translations: {
      de: 'Burkina Faso',
      ru: 'Буркина-Фасо',
      pt: 'Burquina Faso',
      'pt-BR': 'Burkina Faso',
      kr: '부르키나 파소',
      hr: 'Burkina Faso',
      it: 'Burkina Faso',
      fr: 'Burkina Faso',
      'zh-cn': '布基纳法索',
      es: 'Burkina Faso',
      uk: 'Буркіна-Фасо',
      ja: 'ブルキナファソ',
      fa: 'بورکینافاسو',
      pl: 'Burkina Faso',
      nl: 'Burkina Faso',
      tr: 'Burkina Faso'
    },
    name: 'Burkina Faso',
    phone_code: '226'
  },
  {
    code: 'BI',
    emoji: '🇧🇮',
    translations: {
      de: 'Burundi',
      ru: 'Бурунди',
      pt: 'Burúndi',
      'pt-BR': 'Burundi',
      kr: '부룬디',
      hr: 'Burundi',
      it: 'Burundi',
      fr: 'Burundi',
      'zh-cn': '布隆迪',
      es: 'Burundi',
      uk: 'Бурунді',
      ja: 'ブルンジ',
      fa: 'بوروندی',
      pl: 'Burundi',
      nl: 'Burundi',
      tr: 'Burundi'
    },
    name: 'Burundi',
    phone_code: '257'
  },
  {
    code: 'KH',
    emoji: '🇰🇭',
    translations: {
      de: 'Kambodscha',
      ru: 'Камбоджа',
      pt: 'Camboja',
      'pt-BR': 'Camboja',
      kr: '캄보디아',
      hr: 'Kambodža',
      it: 'Cambogia',
      fr: 'Cambodge',
      'zh-cn': '柬埔寨',
      es: 'Camboya',
      uk: 'Камбоджа',
      ja: 'カンボジア',
      fa: 'کامبوج',
      pl: 'Kambodża',
      nl: 'Cambodja',
      tr: 'Kamboçya'
    },
    name: 'Cambodia',
    phone_code: '855'
  },
  {
    code: 'CM',
    emoji: '🇨🇲',
    translations: {
      de: 'Kamerun',
      ru: 'Камерун',
      pt: 'Camarões',
      'pt-BR': 'Camarões',
      kr: '카메룬',
      hr: 'Kamerun',
      it: 'Camerun',
      fr: 'Cameroun',
      'zh-cn': '喀麦隆',
      es: 'Camerún',
      uk: 'Камерун',
      ja: 'カメルーン',
      fa: 'کامرون',
      pl: 'Kamerun',
      nl: 'Kameroen',
      tr: 'Kamerun'
    },
    name: 'Cameroon',
    phone_code: '237'
  },
  {
    code: 'CA',
    emoji: '🇨🇦',
    translations: {
      de: 'Kanada',
      ru: 'Канада',
      pt: 'Canadá',
      'pt-BR': 'Canadá',
      kr: '캐나다',
      hr: 'Kanada',
      it: 'Canada',
      fr: 'Canada',
      'zh-cn': '加拿大',
      es: 'Canadá',
      uk: 'Канада',
      ja: 'カナダ',
      fa: 'کانادا',
      pl: 'Kanada',
      nl: 'Canada',
      tr: 'Kanada'
    },
    name: 'Canada',
    phone_code: '1'
  },
  {
    code: 'CV',
    emoji: '🇨🇻',
    translations: {
      de: 'Kap Verde',
      ru: 'Кабо-Верде',
      pt: 'Cabo Verde',
      'pt-BR': 'Cabo Verde',
      kr: '카보베르데',
      hr: 'Zelenortska Republika',
      it: 'Capo Verde',
      fr: 'Cap Vert',
      'zh-cn': '佛得角',
      es: 'Cabo Verde',
      uk: 'Кабо-Верде',
      ja: 'カーボベルデ',
      fa: 'کیپ ورد',
      pl: 'Republika Zielonego Przylądka',
      nl: 'Kaapverdië',
      tr: 'Cabo Verde'
    },
    name: 'Cape Verde',
    phone_code: '238'
  },
  {
    code: 'KY',
    emoji: '🇰🇾',
    translations: {
      de: 'Kaimaninseln',
      ru: 'Каймановы острова',
      pt: 'Ilhas Caimão',
      'pt-BR': 'Ilhas Cayman',
      kr: '케이먼 제도',
      hr: 'Kajmanski otoci',
      it: 'Isole Cayman',
      fr: 'Îles Caïmans',
      'zh-cn': '开曼群岛',
      es: 'Islas Caimán',
      uk: 'Кайманові острови',
      ja: 'ケイマン諸島',
      fa: 'جزایر کیمن',
      pl: 'Kajmany',
      nl: 'Caymaneilanden',
      tr: 'Cayman Adalari'
    },
    name: 'Cayman Islands',
    phone_code: '1'
  },
  {
    code: 'CF',
    emoji: '🇨🇫',
    translations: {
      de: 'Zentralafrikanische Republik',
      ru: 'Центральноафриканская Республика',
      pt: 'República Centro-Africana',
      'pt-BR': 'República Centro-Africana',
      kr: '중앙아프리카 공화국',
      hr: 'Srednjoafrička Republika',
      it: 'Repubblica Centrafricana',
      fr: 'République centrafricaine',
      'zh-cn': '中非',
      es: 'República Centroafricana',
      uk: 'Центральноафриканська Республіка',
      ja: '中央アフリカ共和国',
      fa: 'جمهوری آفریقای مرکزی',
      pl: 'Republika Środkowoafrykańska',
      nl: 'Centraal-Afrikaanse Republiek',
      tr: 'Orta Afrika Cumhuriyeti'
    },
    name: 'Central African Republic',
    phone_code: '236'
  },
  {
    code: 'TD',
    emoji: '🇹🇩',
    translations: {
      de: 'Tschad',
      ru: 'Чад',
      pt: 'Chade',
      'pt-BR': 'Chade',
      kr: '차드',
      hr: 'Čad',
      it: 'Ciad',
      fr: 'Tchad',
      'zh-cn': '乍得',
      es: 'Chad',
      uk: 'Чад.',
      ja: 'チャド',
      fa: 'چاد',
      pl: 'Czad',
      nl: 'Tsjaad',
      tr: 'Çad'
    },
    name: 'Chad',
    phone_code: '235'
  },
  {
    code: 'CL',
    emoji: '🇨🇱',
    translations: {
      de: 'Chile',
      ru: 'Чили',
      pt: 'Chile',
      'pt-BR': 'Chile',
      kr: '칠리',
      hr: 'Čile',
      it: 'Cile',
      fr: 'Chili',
      'zh-cn': '智利',
      es: 'Chile',
      uk: 'Чилі',
      ja: 'チリ',
      fa: 'شیلی',
      pl: 'Chile',
      nl: 'Chili',
      tr: 'Şili'
    },
    name: 'Chile',
    phone_code: '56'
  },
  {
    code: 'CN',
    emoji: '🇨🇳',
    translations: {
      de: 'China',
      ru: 'Китай',
      pt: 'China',
      'pt-BR': 'China',
      kr: '중국',
      hr: 'Kina',
      it: 'Cina',
      fr: 'Chine',
      'zh-cn': '中国',
      es: 'China',
      uk: 'Китай',
      ja: '中国',
      fa: 'چین',
      pl: 'Chiny',
      nl: 'China',
      tr: 'Çin'
    },
    name: 'China',
    phone_code: '86'
  },
  {
    code: 'CX',
    emoji: '🇨🇽',
    translations: {
      de: 'Weihnachtsinsel',
      ru: 'Остров Рождества',
      pt: 'Ilha do Natal',
      'pt-BR': 'Ilha Christmas',
      kr: '크리스마스 섬',
      hr: 'Božićni otok',
      it: 'Isola di Natale',
      fr: 'Île Christmas',
      'zh-cn': '圣诞岛',
      es: 'Isla de Navidad',
      uk: 'Острів Різдва',
      ja: 'クリスマス島',
      fa: 'جزیره کریسمس',
      pl: 'Wyspa Bożego Narodzenia',
      nl: 'Christmaseiland',
      tr: 'Christmas Adasi'
    },
    name: 'Christmas Island',
    phone_code: '61'
  },
  {
    code: 'CC',
    emoji: '🇨🇨',
    translations: {
      de: 'Kokosinseln',
      ru: 'Кокосовые (Килинг) острова',
      pt: 'Ilhas dos Cocos',
      'pt-BR': 'Ilhas Cocos',
      kr: '코코스 제도',
      hr: 'Kokosovi Otoci',
      it: 'Isole Cocos e Keeling',
      fr: 'Îles Cocos',
      'zh-cn': '科科斯（基林）群岛',
      es: 'Islas Cocos o Islas Keeling',
      uk: 'Кокосові (Кілінг) острови',
      ja: 'ココス（キーリング）諸島',
      fa: 'جزایر کوکوس',
      pl: 'Wyspy Kokosowe (Keelinga)',
      nl: 'Cocoseilanden',
      tr: 'Cocos Adalari'
    },
    name: 'Cocos (Keeling) Islands',
    phone_code: '61'
  },
  {
    code: 'CO',
    emoji: '🇨🇴',
    translations: {
      de: 'Kolumbien',
      ru: 'Колумбия',
      pt: 'Colômbia',
      'pt-BR': 'Colômbia',
      kr: '콜롬비아',
      hr: 'Kolumbija',
      it: 'Colombia',
      fr: 'Colombie',
      'zh-cn': '哥伦比亚',
      es: 'Colombia',
      uk: 'Колумбія',
      ja: 'コロンビア',
      fa: 'کلمبیا',
      pl: 'Kolumbia',
      nl: 'Colombia',
      tr: 'Kolombiya'
    },
    name: 'Colombia',
    phone_code: '57'
  },
  {
    code: 'KM',
    emoji: '🇰🇲',
    translations: {
      de: 'Union der Komoren',
      ru: 'Коморские острова',
      pt: 'Comores',
      'pt-BR': 'Comores',
      kr: '코모로',
      hr: 'Komori',
      it: 'Comore',
      fr: 'Comores',
      'zh-cn': '科摩罗',
      es: 'Comoras',
      uk: 'Коморські острови',
      ja: 'コモロ',
      fa: 'کومور',
      pl: 'Komory',
      nl: 'Comoren',
      tr: 'Komorlar'
    },
    name: 'Comoros',
    phone_code: '269'
  },
  {
    code: 'CG',
    emoji: '🇨🇬',
    translations: {
      de: 'Kongo',
      ru: 'Конго',
      pt: 'Congo',
      'pt-BR': 'Congo',
      kr: '콩고',
      hr: 'Kongo',
      it: 'Congo',
      fr: 'Congo',
      'zh-cn': '刚果',
      es: 'Congo',
      uk: 'Конго',
      ja: 'コンゴ共和国',
      fa: 'کنگو',
      pl: 'Kongo',
      nl: 'Congo [Republiek]',
      tr: 'Kongo'
    },
    name: 'Congo',
    phone_code: '242'
  },
  {
    code: 'CK',
    emoji: '🇨🇰',
    translations: {
      de: 'Cookinseln',
      ru: 'Острова Кука',
      pt: 'Ilhas Cook',
      'pt-BR': 'Ilhas Cook',
      kr: '쿡 제도',
      hr: 'Cookovo Otočje',
      it: 'Isole Cook',
      fr: 'Îles Cook',
      'zh-cn': '库克群岛',
      es: 'Islas Cook',
      uk: 'Острови Кука',
      ja: 'クック諸島',
      fa: 'جزایر کوک',
      pl: 'Wyspy Cooka',
      nl: 'Cookeilanden',
      tr: 'Cook Adalari'
    },
    name: 'Cook Islands',
    phone_code: '682'
  },
  {
    code: 'CR',
    emoji: '🇨🇷',
    translations: {
      de: 'Costa Rica',
      ru: 'Коста-Рика',
      pt: 'Costa Rica',
      'pt-BR': 'Costa Rica',
      kr: '코스타리카',
      hr: 'Kostarika',
      it: 'Costa Rica',
      fr: 'Costa Rica',
      'zh-cn': '哥斯达黎加',
      es: 'Costa Rica',
      uk: 'Коста-Ріка',
      ja: 'コスタリカ',
      fa: 'کاستاریکا',
      pl: 'Kostaryka',
      nl: 'Costa Rica',
      tr: 'Kosta Rika'
    },
    name: 'Costa Rica',
    phone_code: '506'
  },
  {
    code: 'CI',
    emoji: '🇨🇮',
    translations: {
      de: 'Elfenbeinküste',
      ru: "Кот-д'Ивуар (Берег Слоновой Кости)",
      pt: 'Costa do Marfim',
      'pt-BR': 'Costa do Marfim',
      kr: '코트디부아르',
      hr: 'Obala Bjelokosti',
      it: "Costa D'Avorio",
      fr: "Côte d'Ivoire",
      'zh-cn': '科特迪瓦',
      es: 'Costa de Marfil',
      uk: "Кот-д'Івуар (Берег Слонової Кістки)",
      ja: 'コートジボワール',
      fa: 'ساحل عاج',
      pl: "Cote D'Ivoire (Wybrzeże Kości Słoniowej)",
      nl: 'Ivoorkust',
      tr: 'Kotdivuar'
    },
    name: "Cote D'Ivoire (Ivory Coast)",
    phone_code: '225'
  },
  {
    code: 'HR',
    emoji: '🇭🇷',
    translations: {
      de: 'Kroatien',
      ru: 'Хорватия',
      pt: 'Croácia',
      'pt-BR': 'Croácia',
      kr: '크로아티아',
      hr: 'Hrvatska',
      it: 'Croazia',
      fr: 'Croatie',
      'zh-cn': '克罗地亚',
      es: 'Croacia',
      uk: 'Хорватія',
      ja: 'クロアチア',
      fa: 'کرواسی',
      pl: 'Chorwacja',
      nl: 'Kroatië',
      tr: 'Hirvatistan'
    },
    name: 'Croatia',
    phone_code: '385'
  },
  {
    code: 'CU',
    emoji: '🇨🇺',
    translations: {
      de: 'Kuba',
      ru: 'Куба',
      pt: 'Cuba',
      'pt-BR': 'Cuba',
      kr: '쿠바',
      hr: 'Kuba',
      it: 'Cuba',
      fr: 'Cuba',
      'zh-cn': '古巴',
      es: 'Cuba',
      uk: 'Куба',
      ja: 'キューバ',
      fa: 'کوبا',
      pl: 'Kuba',
      nl: 'Cuba',
      tr: 'Küba'
    },
    name: 'Cuba',
    phone_code: '53'
  },
  {
    code: 'CW',
    emoji: '🇨🇼',
    translations: {
      de: 'Curaçao',
      ru: 'Кюрасао',
      pt: 'Curaçao',
      'pt-BR': 'Curaçao',
      kr: '퀴라소',
      it: 'Curaçao',
      fr: 'Curaçao',
      'zh-cn': '库拉索',
      uk: 'Кюрасао',
      fa: 'کوراسائو',
      pl: 'Curaçao',
      nl: 'Curaçao',
      tr: 'Curaçao'
    },
    name: 'Curaçao',
    phone_code: '599'
  },
  {
    code: 'CY',
    emoji: '🇨🇾',
    translations: {
      de: 'Zypern',
      ru: 'Кипр',
      pt: 'Chipre',
      'pt-BR': 'Chipre',
      kr: '키프로스',
      hr: 'Cipar',
      it: 'Cipro',
      fr: 'Chypre',
      'zh-cn': '塞浦路斯',
      es: 'Chipre',
      uk: 'Кіпр',
      ja: 'キプロス',
      fa: 'قبرس',
      pl: 'Cypr',
      nl: 'Cyprus',
      tr: 'Kuzey Kıbrıs Türk Cumhuriyeti'
    },
    name: 'Cyprus',
    phone_code: '357'
  },
  {
    code: 'CZ',
    emoji: '🇨🇿',
    translations: {
      de: 'Tschechische Republik',
      ru: 'Чешская Республика',
      pt: 'República Checa',
      'pt-BR': 'República Tcheca',
      kr: '체코',
      hr: 'Češka',
      it: 'Repubblica Ceca',
      fr: 'République tchèque',
      'zh-cn': '捷克',
      es: 'República Checa',
      uk: 'Чеська Республіка',
      ja: 'チェコ',
      fa: 'جمهوری چک',
      pl: 'Republika Czeska',
      nl: 'Tsjechië',
      tr: 'Çekya'
    },
    name: 'Czech Republic',
    phone_code: '420'
  },
  {
    code: 'CD',
    emoji: '🇨🇩',
    translations: {
      de: 'Kongo (Dem. Rep.)',
      ru: 'Демократическая Республика Конго',
      pt: 'RD Congo',
      'pt-BR': 'RD Congo',
      kr: '콩고 민주 공화국',
      hr: 'Kongo, Demokratska Republika',
      it: 'Congo (Rep. Dem.)',
      fr: 'Congo (Rép. dém.)',
      'zh-cn': '刚果（金）',
      es: 'Congo (Rep. Dem.)',
      uk: 'Демократична Республіка Конго',
      ja: 'コンゴ民主共和国',
      fa: 'جمهوری کنگو',
      pl: 'Demokratyczna Republika Konga',
      nl: 'Congo [DRC]',
      tr: 'Kongo Demokratik Cumhuriyeti'
    },
    name: 'Democratic Republic of the Congo',
    phone_code: '243'
  },
  {
    code: 'DK',
    emoji: '🇩🇰',
    translations: {
      de: 'Dänemark',
      ru: 'Дания',
      pt: 'Dinamarca',
      'pt-BR': 'Dinamarca',
      kr: '덴마크',
      hr: 'Danska',
      it: 'Danimarca',
      fr: 'Danemark',
      'zh-cn': '丹麦',
      es: 'Dinamarca',
      uk: 'Данія',
      ja: 'デンマーク',
      fa: 'دانمارک',
      pl: 'Dania',
      nl: 'Denemarken',
      tr: 'Danimarka'
    },
    name: 'Denmark',
    phone_code: '45'
  },
  {
    code: 'DJ',
    emoji: '🇩🇯',
    translations: {
      de: 'Dschibuti',
      ru: 'Джибути',
      pt: 'Djibuti',
      'pt-BR': 'Djibuti',
      kr: '지부티',
      hr: 'Džibuti',
      it: 'Gibuti',
      fr: 'Djibouti',
      'zh-cn': '吉布提',
      es: 'Yibuti',
      uk: 'Джибуті',
      ja: 'ジブチ',
      fa: 'جیبوتی',
      pl: 'Dżibuti',
      nl: 'Djibouti',
      tr: 'Cibuti'
    },
    name: 'Djibouti',
    phone_code: '253'
  },
  {
    code: 'DM',
    emoji: '🇩🇲',
    translations: {
      de: 'Dominica',
      ru: 'Доминика',
      pt: 'Dominica',
      'pt-BR': 'Dominica',
      kr: '도미니카 연방',
      hr: 'Dominika',
      it: 'Dominica',
      fr: 'Dominique',
      'zh-cn': '多米尼加',
      es: 'Dominica',
      uk: 'Домініка',
      ja: 'ドミニカ国',
      fa: 'دومینیکا',
      pl: 'Dominika',
      nl: 'Dominica',
      tr: 'Dominika'
    },
    name: 'Dominica',
    phone_code: '1'
  },
  {
    code: 'DO',
    emoji: '🇩🇴',
    translations: {
      de: 'Dominikanische Republik',
      ru: 'Доминиканская Республика',
      pt: 'República Dominicana',
      'pt-BR': 'República Dominicana',
      kr: '도미니카 공화국',
      hr: 'Dominikanska Republika',
      it: 'Repubblica Dominicana',
      fr: 'République dominicaine',
      'zh-cn': '多明尼加共和国',
      es: 'República Dominicana',
      uk: 'Домініканська Республіка',
      ja: 'ドミニカ共和国',
      fa: 'جمهوری دومینیکن',
      pl: 'Republika Dominikańska',
      nl: 'Dominicaanse Republiek',
      tr: 'Dominik Cumhuriyeti'
    },
    name: 'Dominican Republic',
    phone_code: '1'
  },
  {
    code: 'EC',
    emoji: '🇪🇨',
    translations: {
      de: 'Ecuador',
      ru: 'Эквадор',
      pt: 'Equador',
      'pt-BR': 'Equador',
      kr: '에콰도르',
      hr: 'Ekvador',
      it: 'Ecuador',
      fr: 'Équateur',
      'zh-cn': '厄瓜多尔',
      es: 'Ecuador',
      uk: 'Еквадор',
      ja: 'エクアドル',
      fa: 'اکوادور',
      pl: 'Ekwador',
      nl: 'Ecuador',
      tr: 'Ekvator'
    },
    name: 'Ecuador',
    phone_code: '593'
  },
  {
    code: 'EG',
    emoji: '🇪🇬',
    translations: {
      de: 'Ägypten',
      ru: 'Египет',
      pt: 'Egipto',
      'pt-BR': 'Egito',
      kr: '이집트',
      hr: 'Egipat',
      it: 'Egitto',
      fr: 'Égypte',
      'zh-cn': '埃及',
      es: 'Egipto',
      uk: 'Єгипет',
      ja: 'エジプト',
      fa: 'مصر',
      pl: 'Egipt',
      nl: 'Egypte',
      tr: 'Mısır'
    },
    name: 'Egypt',
    phone_code: '20'
  },
  {
    code: 'SV',
    emoji: '🇸🇻',
    translations: {
      de: 'El Salvador',
      ru: 'Сальвадор',
      pt: 'El Salvador',
      'pt-BR': 'El Salvador',
      kr: '엘살바도르',
      hr: 'Salvador',
      it: 'El Salvador',
      fr: 'Salvador',
      'zh-cn': '萨尔瓦多',
      es: 'El Salvador',
      uk: 'Сальвадор',
      ja: 'エルサルバドル',
      fa: 'السالوادور',
      pl: 'Salwador',
      nl: 'El Salvador',
      tr: 'El Salvador'
    },
    name: 'El Salvador',
    phone_code: '503'
  },
  {
    code: 'GQ',
    emoji: '🇬🇶',
    translations: {
      de: 'Äquatorial-Guinea',
      ru: 'Экваториальная Гвинея',
      pt: 'Guiné Equatorial',
      'pt-BR': 'Guiné Equatorial',
      kr: '적도 기니',
      hr: 'Ekvatorijalna Gvineja',
      it: 'Guinea Equatoriale',
      fr: 'Guinée-Équatoriale',
      'zh-cn': '赤道几内亚',
      es: 'Guinea Ecuatorial',
      uk: 'Екваторіальна Гвінея',
      ja: '赤道ギニア',
      fa: 'گینه استوایی',
      pl: 'Gwinea Równikowa',
      nl: 'Equatoriaal-Guinea',
      tr: 'Ekvator Ginesi'
    },
    name: 'Equatorial Guinea',
    phone_code: '240'
  },
  {
    code: 'ER',
    emoji: '🇪🇷',
    translations: {
      de: 'Eritrea',
      ru: 'Эритрея',
      pt: 'Eritreia',
      'pt-BR': 'Eritreia',
      kr: '에리트레아',
      hr: 'Eritreja',
      it: 'Eritrea',
      fr: 'Érythrée',
      'zh-cn': '厄立特里亚',
      es: 'Eritrea',
      uk: 'Еритрея',
      ja: 'エリトリア',
      fa: 'اریتره',
      pl: 'Erytrea',
      nl: 'Eritrea',
      tr: 'Eritre'
    },
    name: 'Eritrea',
    phone_code: '291'
  },
  {
    code: 'EE',
    emoji: '🇪🇪',
    translations: {
      de: 'Estland',
      ru: 'Эстония',
      pt: 'Estónia',
      'pt-BR': 'Estônia',
      kr: '에스토니아',
      hr: 'Estonija',
      it: 'Estonia',
      fr: 'Estonie',
      'zh-cn': '爱沙尼亚',
      es: 'Estonia',
      uk: 'Естонія',
      ja: 'エストニア',
      fa: 'استونی',
      pl: 'Estonia',
      nl: 'Estland',
      tr: 'Estonya'
    },
    name: 'Estonia',
    phone_code: '372'
  },
  {
    code: 'SZ',
    emoji: '🇸🇿',
    translations: {
      de: 'Swasiland',
      ru: 'Эсватини',
      pt: 'Suazilândia',
      'pt-BR': 'Suazilândia',
      kr: '에스와티니',
      hr: 'Svazi',
      it: 'Swaziland',
      fr: 'Swaziland',
      'zh-cn': '斯威士兰',
      es: 'Suazilandia',
      uk: 'Есватіні',
      ja: 'スワジランド',
      fa: 'سوازیلند',
      pl: 'Eswatini',
      nl: 'Swaziland',
      tr: 'Esvatini'
    },
    name: 'Eswatini',
    phone_code: '268'
  },
  {
    code: 'ET',
    emoji: '🇪🇹',
    translations: {
      de: 'Äthiopien',
      ru: 'Эфиопия',
      pt: 'Etiópia',
      'pt-BR': 'Etiópia',
      kr: '에티오피아',
      hr: 'Etiopija',
      it: 'Etiopia',
      fr: 'Éthiopie',
      'zh-cn': '埃塞俄比亚',
      es: 'Etiopía',
      uk: 'Ефіопія',
      ja: 'エチオピア',
      fa: 'اتیوپی',
      pl: 'Etiopia',
      nl: 'Ethiopië',
      tr: 'Etiyopya'
    },
    name: 'Ethiopia',
    phone_code: '251'
  },
  {
    code: 'FK',
    emoji: '🇫🇰',
    translations: {
      de: 'Falklandinseln',
      ru: 'Фолклендские острова',
      pt: 'Ilhas Falkland',
      'pt-BR': 'Ilhas Malvinas',
      kr: '포클랜드 제도',
      hr: 'Falklandski Otoci',
      it: 'Isole Falkland o Isole Malvine',
      fr: 'Îles Malouines',
      'zh-cn': '福克兰群岛',
      es: 'Islas Malvinas',
      uk: 'Фолклендські острови',
      ja: 'フォークランド（マルビナス）諸島',
      fa: 'جزایر فالکلند',
      pl: 'Falklandy',
      nl: 'Falklandeilanden [Islas Malvinas]',
      tr: 'Falkland Adalari'
    },
    name: 'Falkland Islands',
    phone_code: '500'
  },
  {
    code: 'FO',
    emoji: '🇫🇴',
    translations: {
      de: 'Färöer-Inseln',
      ru: 'Фарерские острова',
      pt: 'Ilhas Faroé',
      'pt-BR': 'Ilhas Faroé',
      kr: '페로 제도',
      hr: 'Farski Otoci',
      it: 'Isole Far Oer',
      fr: 'Îles Féroé',
      'zh-cn': '法罗群岛',
      es: 'Islas Faroe',
      uk: 'Фарерські острови',
      ja: 'フェロー諸島',
      fa: 'جزایر فارو',
      pl: 'Wyspy Owcze',
      nl: 'Faeröer',
      tr: 'Faroe Adalari'
    },
    name: 'Faroe Islands',
    phone_code: '298'
  },
  {
    code: 'FJ',
    emoji: '🇫🇯',
    translations: {
      de: 'Fidschi',
      ru: 'Острова Фиджи',
      pt: 'Fiji',
      'pt-BR': 'Fiji',
      kr: '피지',
      hr: 'Fiđi',
      it: 'Figi',
      fr: 'Fidji',
      'zh-cn': '斐济',
      es: 'Fiyi',
      uk: 'Острови Фіджі',
      ja: 'フィジー',
      fa: 'فیجی',
      pl: 'Wyspy Fidżi',
      nl: 'Fiji',
      tr: 'Fiji'
    },
    name: 'Fiji Islands',
    phone_code: '679'
  },
  {
    code: 'FI',
    emoji: '🇫🇮',
    translations: {
      de: 'Finnland',
      ru: 'Финляндия',
      pt: 'Finlândia',
      'pt-BR': 'Finlândia',
      kr: '핀란드',
      hr: 'Finska',
      it: 'Finlandia',
      fr: 'Finlande',
      'zh-cn': '芬兰',
      es: 'Finlandia',
      uk: 'Фінляндія',
      ja: 'フィンランド',
      fa: 'فنلاند',
      pl: 'Finlandia',
      nl: 'Finland',
      tr: 'Finlandiya'
    },
    name: 'Finland',
    phone_code: '358'
  },
  {
    code: 'FR',
    emoji: '🇫🇷',
    translations: {
      de: 'Frankreich',
      ru: 'Франция',
      pt: 'França',
      'pt-BR': 'França',
      kr: '프랑스',
      hr: 'Francuska',
      it: 'Francia',
      fr: 'France',
      'zh-cn': '法国',
      es: 'Francia',
      uk: 'Франція',
      ja: 'フランス',
      fa: 'فرانسه',
      pl: 'Francja',
      nl: 'Frankrijk',
      tr: 'Fransa'
    },
    name: 'France',
    phone_code: '33'
  },
  {
    code: 'GF',
    emoji: '🇬🇫',
    translations: {
      de: 'Französisch Guyana',
      ru: 'Французская Гвиана',
      pt: 'Guiana Francesa',
      'pt-BR': 'Guiana Francesa',
      kr: '프랑스령 기아나',
      hr: 'Francuska Gvajana',
      it: 'Guyana francese',
      fr: 'Guayane',
      'zh-cn': '法属圭亚那',
      es: 'Guayana Francesa',
      uk: 'Французька Гвіана',
      ja: 'フランス領ギアナ',
      fa: 'گویان فرانسه',
      pl: 'Gujana Francuska',
      nl: 'Frans-Guyana',
      tr: 'Fransiz Guyanasi'
    },
    name: 'French Guiana',
    phone_code: '594'
  },
  {
    code: 'PF',
    emoji: '🇵🇫',
    translations: {
      de: 'Französisch-Polynesien',
      ru: 'Французская Полинезия',
      pt: 'Polinésia Francesa',
      'pt-BR': 'Polinésia Francesa',
      kr: '프랑스령 폴리네시아',
      hr: 'Francuska Polinezija',
      it: 'Polinesia Francese',
      fr: 'Polynésie française',
      'zh-cn': '法属波利尼西亚',
      es: 'Polinesia Francesa',
      uk: 'Французька Полінезія',
      ja: 'フランス領ポリネシア',
      fa: 'پلی‌نزی فرانسه',
      pl: 'Polinezja Francuska',
      nl: 'Frans-Polynesië',
      tr: 'Fransiz Polinezyasi'
    },
    name: 'French Polynesia',
    phone_code: '689'
  },
  {
    code: 'TF',
    emoji: '🇹🇫',
    translations: {
      de: 'Französische Süd- und Antarktisgebiete',
      ru: 'Французские южные территории',
      pt: 'Terras Austrais e Antárticas Francesas',
      'pt-BR': 'Terras Austrais e Antárticas Francesas',
      kr: '프랑스령 남방 및 남극',
      hr: 'Francuski južni i antarktički teritoriji',
      it: 'Territori Francesi del Sud',
      fr: 'Terres australes et antarctiques françaises',
      'zh-cn': '法属南部领地',
      es: 'Tierras Australes y Antárticas Francesas',
      uk: 'Французькі південні території',
      ja: 'フランス領南方・南極地域',
      fa: 'سرزمین‌های جنوبی و جنوبگانی فرانسه',
      pl: 'Francuskie terytoria południowe',
      nl: 'Franse Gebieden in de zuidelijke Indische Oceaan',
      tr: 'Fransiz Güney Topraklari'
    },
    name: 'French Southern Territories',
    phone_code: '262'
  },
  {
    code: 'GA',
    emoji: '🇬🇦',
    translations: {
      de: 'Gabun',
      ru: 'Габон',
      pt: 'Gabão',
      'pt-BR': 'Gabão',
      kr: '가봉',
      hr: 'Gabon',
      it: 'Gabon',
      fr: 'Gabon',
      'zh-cn': '加蓬',
      es: 'Gabón',
      uk: 'Габон',
      ja: 'ガボン',
      fa: 'گابن',
      pl: 'Gabon',
      nl: 'Gabon',
      tr: 'Gabon'
    },
    name: 'Gabon',
    phone_code: '241'
  },
  {
    code: 'GE',
    emoji: '🇬🇪',
    translations: {
      de: 'Georgien',
      ru: 'Джорджия',
      pt: 'Geórgia',
      'pt-BR': 'Geórgia',
      kr: '조지아',
      hr: 'Gruzija',
      it: 'Georgia',
      fr: 'Géorgie',
      'zh-cn': '格鲁吉亚',
      es: 'Georgia',
      uk: 'Грузія',
      ja: 'グルジア',
      fa: 'گرجستان',
      pl: 'Gruzja',
      nl: 'Georgië',
      tr: 'Gürcistan'
    },
    name: 'Georgia',
    phone_code: '995'
  },
  {
    code: 'DE',
    emoji: '🇩🇪',
    translations: {
      de: 'Deutschland',
      ru: 'Германия',
      pt: 'Alemanha',
      'pt-BR': 'Alemanha',
      kr: '독일',
      hr: 'Njemačka',
      it: 'Germania',
      fr: 'Allemagne',
      'zh-cn': '德国',
      es: 'Alemania',
      uk: 'Німеччина',
      ja: 'ドイツ',
      fa: 'آلمان',
      pl: 'Niemcy',
      nl: 'Duitsland',
      tr: 'Almanya'
    },
    name: 'Germany',
    phone_code: '49'
  },
  {
    code: 'GH',
    emoji: '🇬🇭',
    translations: {
      de: 'Ghana',
      ru: 'Гана',
      pt: 'Gana',
      'pt-BR': 'Gana',
      kr: '가나',
      hr: 'Gana',
      it: 'Ghana',
      fr: 'Ghana',
      'zh-cn': '加纳',
      es: 'Ghana',
      uk: 'Гана',
      ja: 'ガーナ',
      fa: 'غنا',
      pl: 'Ghana',
      nl: 'Ghana',
      tr: 'Gana'
    },
    name: 'Ghana',
    phone_code: '233'
  },
  {
    code: 'GI',
    emoji: '🇬🇮',
    translations: {
      de: 'Gibraltar',
      ru: 'Гибралтар',
      pt: 'Gibraltar',
      'pt-BR': 'Gibraltar',
      kr: '지브롤터',
      hr: 'Gibraltar',
      it: 'Gibilterra',
      fr: 'Gibraltar',
      'zh-cn': '直布罗陀',
      es: 'Gibraltar',
      uk: 'Гібралтар',
      ja: 'ジブラルタル',
      fa: 'جبل‌طارق',
      pl: 'Gibraltar',
      nl: 'Gibraltar',
      tr: 'Cebelitarik'
    },
    name: 'Gibraltar',
    phone_code: '350'
  },
  {
    code: 'GR',
    emoji: '🇬🇷',
    translations: {
      de: 'Griechenland',
      ru: 'Греция',
      pt: 'Grécia',
      'pt-BR': 'Grécia',
      kr: '그리스',
      hr: 'Grčka',
      it: 'Grecia',
      fr: 'Grèce',
      'zh-cn': '希腊',
      es: 'Grecia',
      uk: 'Греція',
      ja: 'ギリシャ',
      fa: 'یونان',
      pl: 'Grecja',
      nl: 'Griekenland',
      tr: 'Yunanistan'
    },
    name: 'Greece',
    phone_code: '30'
  },
  {
    code: 'GL',
    emoji: '🇬🇱',
    translations: {
      de: 'Grönland',
      ru: 'Гренландия',
      pt: 'Gronelândia',
      'pt-BR': 'Groelândia',
      kr: '그린란드',
      hr: 'Grenland',
      it: 'Groenlandia',
      fr: 'Groenland',
      'zh-cn': '格陵兰岛',
      es: 'Groenlandia',
      uk: 'Гренландія',
      ja: 'グリーンランド',
      fa: 'گرینلند',
      pl: 'Grenlandia',
      nl: 'Groenland',
      tr: 'Grönland'
    },
    name: 'Greenland',
    phone_code: '299'
  },
  {
    code: 'GD',
    emoji: '🇬🇩',
    translations: {
      de: 'Grenada',
      ru: 'Гренада',
      pt: 'Granada',
      'pt-BR': 'Granada',
      kr: '그레나다',
      hr: 'Grenada',
      it: 'Grenada',
      fr: 'Grenade',
      'zh-cn': '格林纳达',
      es: 'Grenada',
      uk: 'Гренада',
      ja: 'グレナダ',
      fa: 'گرنادا',
      pl: 'Grenada',
      nl: 'Grenada',
      tr: 'Grenada'
    },
    name: 'Grenada',
    phone_code: '1'
  },
  {
    code: 'GP',
    emoji: '🇬🇵',
    translations: {
      de: 'Guadeloupe',
      ru: 'Гваделупа',
      pt: 'Guadalupe',
      'pt-BR': 'Guadalupe',
      kr: '과들루프',
      hr: 'Gvadalupa',
      it: 'Guadeloupa',
      fr: 'Guadeloupe',
      'zh-cn': '瓜德罗普岛',
      es: 'Guadalupe',
      uk: 'Гваделупа',
      ja: 'グアドループ',
      fa: 'جزیره گوادلوپ',
      pl: 'Gwadelupa',
      nl: 'Guadeloupe',
      tr: 'Guadeloupe'
    },
    name: 'Guadeloupe',
    phone_code: '590'
  },
  {
    code: 'GU',
    emoji: '🇬🇺',
    translations: {
      de: 'Guam',
      ru: 'Гуам',
      pt: 'Guame',
      'pt-BR': 'Guam',
      kr: '괌',
      hr: 'Guam',
      it: 'Guam',
      fr: 'Guam',
      'zh-cn': '关岛',
      es: 'Guam',
      uk: 'Гуам',
      ja: 'グアム',
      fa: 'گوام',
      pl: 'Guam',
      nl: 'Guam',
      tr: 'Guam'
    },
    name: 'Guam',
    phone_code: '1'
  },
  {
    code: 'GT',
    emoji: '🇬🇹',
    translations: {
      de: 'Guatemala',
      ru: 'Гватемала',
      pt: 'Guatemala',
      'pt-BR': 'Guatemala',
      kr: '과테말라',
      hr: 'Gvatemala',
      it: 'Guatemala',
      fr: 'Guatemala',
      'zh-cn': '危地马拉',
      es: 'Guatemala',
      uk: 'Гватемала',
      ja: 'グアテマラ',
      fa: 'گواتمالا',
      pl: 'Gwatemala',
      nl: 'Guatemala',
      tr: 'Guatemala'
    },
    name: 'Guatemala',
    phone_code: '502'
  },
  {
    code: 'GG',
    emoji: '🇬🇬',
    translations: {
      de: 'Guernsey',
      ru: 'Гернси и Олдерни',
      pt: 'Guernsey',
      'pt-BR': 'Guernsey',
      kr: '건지, 올더니',
      hr: 'Guernsey',
      it: 'Guernsey',
      fr: 'Guernesey',
      'zh-cn': '根西岛',
      es: 'Guernsey',
      uk: 'Гернсі та Олдерні',
      ja: 'ガーンジー',
      fa: 'گرنزی',
      pl: 'Guernsey i Alderney',
      nl: 'Guernsey',
      tr: 'Alderney'
    },
    name: 'Guernsey and Alderney',
    phone_code: '44'
  },
  {
    code: 'GN',
    emoji: '🇬🇳',
    translations: {
      de: 'Guinea',
      ru: 'Гвинея',
      pt: 'Guiné',
      'pt-BR': 'Guiné',
      kr: '기니',
      hr: 'Gvineja',
      it: 'Guinea',
      fr: 'Guinée',
      'zh-cn': '几内亚',
      es: 'Guinea',
      uk: 'Гвінея',
      ja: 'ギニア',
      fa: 'گینه',
      pl: 'Gwinea',
      nl: 'Guinee',
      tr: 'Gine'
    },
    name: 'Guinea',
    phone_code: '224'
  },
  {
    code: 'GW',
    emoji: '🇬🇼',
    translations: {
      de: 'Guinea-Bissau',
      ru: 'Гвинея-Бисау',
      pt: 'Guiné-Bissau',
      'pt-BR': 'Guiné-Bissau',
      kr: '기니비사우',
      hr: 'Gvineja Bisau',
      it: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      'zh-cn': '几内亚比绍',
      es: 'Guinea-Bisáu',
      uk: 'Гвінея-Бісау',
      ja: 'ギニアビサウ',
      fa: 'گینه بیسائو',
      pl: 'Gwinea Bissau',
      nl: 'Guinee-Bissau',
      tr: 'Gine-bissau'
    },
    name: 'Guinea-Bissau',
    phone_code: '245'
  },
  {
    code: 'GY',
    emoji: '🇬🇾',
    translations: {
      de: 'Guyana',
      ru: 'Гайана',
      pt: 'Guiana',
      'pt-BR': 'Guiana',
      kr: '가이아나',
      hr: 'Gvajana',
      it: 'Guyana',
      fr: 'Guyane',
      'zh-cn': '圭亚那',
      es: 'Guyana',
      uk: 'Гайана',
      ja: 'ガイアナ',
      fa: 'گویان',
      pl: 'Gujana',
      nl: 'Guyana',
      tr: 'Guyana'
    },
    name: 'Guyana',
    phone_code: '592'
  },
  {
    code: 'HT',
    emoji: '🇭🇹',
    translations: {
      de: 'Haiti',
      ru: 'Гаити',
      pt: 'Haiti',
      'pt-BR': 'Haiti',
      kr: '아이티',
      hr: 'Haiti',
      it: 'Haiti',
      fr: 'Haïti',
      'zh-cn': '海地',
      es: 'Haiti',
      uk: 'Гаїті',
      ja: 'ハイチ',
      fa: 'هائیتی',
      pl: 'Haiti',
      nl: 'Haïti',
      tr: 'Haiti'
    },
    name: 'Haiti',
    phone_code: '509'
  },
  {
    code: 'HM',
    emoji: '🇭🇲',
    translations: {
      de: 'Heard und die McDonaldinseln',
      ru: 'Остров Херд и острова Макдональд',
      pt: 'Ilha Heard e Ilhas McDonald',
      'pt-BR': 'Ilha Heard e Ilhas McDonald',
      kr: '허드 맥도날드 제도',
      hr: 'Otok Heard i otočje McDonald',
      it: 'Isole Heard e McDonald',
      fr: 'Îles Heard-et-MacDonald',
      'zh-cn': '赫德·唐纳岛及麦唐纳岛',
      es: 'Islas Heard y McDonald',
      uk: 'Острів Херд та острови Макдональд',
      ja: 'ハード島とマクドナルド諸島',
      fa: 'جزیره هرد و جزایر مک‌دونالد',
      pl: 'Wyspa Heard i Wyspy McDonalda',
      nl: 'Heard- en McDonaldeilanden',
      tr: 'Heard Adasi Ve Mcdonald Adalari'
    },
    name: 'Heard Island and McDonald Islands',
    phone_code: '672'
  },
  {
    code: 'HN',
    emoji: '🇭🇳',
    translations: {
      de: 'Honduras',
      ru: 'Гондурас',
      pt: 'Honduras',
      'pt-BR': 'Honduras',
      kr: '온두라스',
      hr: 'Honduras',
      it: 'Honduras',
      fr: 'Honduras',
      'zh-cn': '洪都拉斯',
      es: 'Honduras',
      uk: 'Гондурас',
      ja: 'ホンジュラス',
      fa: 'هندوراس',
      pl: 'Honduras',
      nl: 'Honduras',
      tr: 'Honduras'
    },
    name: 'Honduras',
    phone_code: '504'
  },
  {
    code: 'HK',
    emoji: '🇭🇰',
    translations: {
      de: 'Hong Kong',
      ru: 'Гонконг С.А.Р.',
      pt: 'Hong Kong',
      'pt-BR': 'Hong Kong',
      kr: '홍콩',
      hr: 'Hong Kong',
      it: 'Hong Kong',
      fr: 'Hong Kong',
      'zh-cn': '中国香港',
      es: 'Hong Kong',
      uk: 'Гонконг САР.',
      ja: '香港',
      fa: 'هنگ‌کنگ',
      pl: 'Hongkong S.A.R.',
      nl: 'Hongkong',
      tr: 'Hong Kong'
    },
    name: 'Hong Kong S.A.R.',
    phone_code: '852'
  },
  {
    code: 'HU',
    emoji: '🇭🇺',
    translations: {
      de: 'Ungarn',
      ru: 'Венгрия',
      pt: 'Hungria',
      'pt-BR': 'Hungria',
      kr: '헝가리',
      hr: 'Mađarska',
      it: 'Ungheria',
      fr: 'Hongrie',
      'zh-cn': '匈牙利',
      es: 'Hungría',
      uk: 'Угорщина',
      ja: 'ハンガリー',
      fa: 'مجارستان',
      pl: 'Węgry',
      nl: 'Hongarije',
      tr: 'Macaristan'
    },
    name: 'Hungary',
    phone_code: '36'
  },
  {
    code: 'IS',
    emoji: '🇮🇸',
    translations: {
      de: 'Island',
      ru: 'Исландия',
      pt: 'Islândia',
      'pt-BR': 'Islândia',
      kr: '아이슬란드',
      hr: 'Island',
      it: 'Islanda',
      fr: 'Islande',
      'zh-cn': '冰岛',
      es: 'Islandia',
      uk: 'Ісландія',
      ja: 'アイスランド',
      fa: 'ایسلند',
      pl: 'Islandia',
      nl: 'IJsland',
      tr: 'İzlanda'
    },
    name: 'Iceland',
    phone_code: '354'
  },
  {
    code: 'IN',
    emoji: '🇮🇳',
    translations: {
      de: 'Indien',
      ru: 'Индия',
      pt: 'Índia',
      'pt-BR': 'Índia',
      kr: '인도',
      hr: 'Indija',
      it: 'India',
      fr: 'Inde',
      'zh-cn': '印度',
      es: 'India',
      uk: 'Індія',
      ja: 'インド',
      fa: 'هند',
      pl: 'Indie',
      nl: 'India',
      tr: 'Hindistan'
    },
    name: 'India',
    phone_code: '91'
  },
  {
    code: 'ID',
    emoji: '🇮🇩',
    translations: {
      de: 'Indonesien',
      ru: 'Индонезия',
      pt: 'Indonésia',
      'pt-BR': 'Indonésia',
      kr: '인도네시아',
      hr: 'Indonezija',
      it: 'Indonesia',
      fr: 'Indonésie',
      'zh-cn': '印度尼西亚',
      es: 'Indonesia',
      uk: 'Індонезія',
      ja: 'インドネシア',
      fa: 'اندونزی',
      pl: 'Indonezja',
      nl: 'Indonesië',
      tr: 'Endonezya'
    },
    name: 'Indonesia',
    phone_code: '62'
  },
  {
    code: 'IR',
    emoji: '🇮🇷',
    translations: {
      de: 'Iran',
      ru: 'Иран',
      pt: 'Irão',
      'pt-BR': 'Irã',
      kr: '이란',
      hr: 'Iran',
      fr: 'Iran',
      'zh-cn': '伊朗',
      es: 'Iran',
      uk: 'Іран',
      ja: 'イラン・イスラム共和国',
      fa: 'ایران',
      pl: 'Iran',
      nl: 'Iran',
      tr: 'İran'
    },
    name: 'Iran',
    phone_code: '98'
  },
  {
    code: 'IQ',
    emoji: '🇮🇶',
    translations: {
      de: 'Irak',
      ru: 'Ирак',
      pt: 'Iraque',
      'pt-BR': 'Iraque',
      kr: '이라크',
      hr: 'Irak',
      it: 'Iraq',
      fr: 'Irak',
      'zh-cn': '伊拉克',
      es: 'Irak',
      uk: 'Ірак',
      ja: 'イラク',
      fa: 'عراق',
      pl: 'Irak',
      nl: 'Irak',
      tr: 'Irak'
    },
    name: 'Iraq',
    phone_code: '964'
  },
  {
    code: 'IE',
    emoji: '🇮🇪',
    translations: {
      de: 'Irland',
      ru: 'Ирландия',
      pt: 'Irlanda',
      'pt-BR': 'Irlanda',
      kr: '아일랜드',
      hr: 'Irska',
      it: 'Irlanda',
      fr: 'Irlande',
      'zh-cn': '爱尔兰',
      es: 'Irlanda',
      uk: 'Ірландія',
      ja: 'アイルランド',
      fa: 'ایرلند',
      pl: 'Irlandia',
      nl: 'Ierland',
      tr: 'İrlanda'
    },
    name: 'Ireland',
    phone_code: '353'
  },
  {
    code: 'IL',
    emoji: '🇮🇱',
    translations: {
      de: 'Israel',
      ru: 'Израиль',
      pt: 'Israel',
      'pt-BR': 'Israel',
      kr: '이스라엘',
      hr: 'Izrael',
      it: 'Israele',
      fr: 'Israël',
      'zh-cn': '以色列',
      es: 'Israel',
      uk: 'Ізраїль',
      ja: 'イスラエル',
      fa: 'اسرائیل',
      pl: 'Izrael',
      nl: 'Israël',
      tr: 'İsrail'
    },
    name: 'Israel',
    phone_code: '972'
  },
  {
    code: 'IT',
    emoji: '🇮🇹',
    translations: {
      de: 'Italien',
      ru: 'Италия',
      pt: 'Itália',
      'pt-BR': 'Itália',
      kr: '이탈리아',
      hr: 'Italija',
      it: 'Italia',
      fr: 'Italie',
      'zh-cn': '意大利',
      es: 'Italia',
      uk: 'Італія',
      ja: 'イタリア',
      fa: 'ایتالیا',
      pl: 'Włochy',
      nl: 'Italië',
      tr: 'İtalya'
    },
    name: 'Italy',
    phone_code: '39'
  },
  {
    code: 'JM',
    emoji: '🇯🇲',
    translations: {
      de: 'Jamaika',
      ru: 'Ямайка',
      pt: 'Jamaica',
      'pt-BR': 'Jamaica',
      kr: '자메이카',
      hr: 'Jamajka',
      it: 'Giamaica',
      fr: 'Jamaïque',
      'zh-cn': '牙买加',
      es: 'Jamaica',
      uk: 'Ямайка',
      ja: 'ジャマイカ',
      fa: 'جامائیکا',
      pl: 'Jamajka',
      nl: 'Jamaica',
      tr: 'Jamaika'
    },
    name: 'Jamaica',
    phone_code: '1'
  },
  {
    code: 'JP',
    emoji: '🇯🇵',
    translations: {
      de: 'Japan',
      ru: 'Япония',
      pt: 'Japão',
      'pt-BR': 'Japão',
      kr: '일본',
      hr: 'Japan',
      it: 'Giappone',
      fr: 'Japon',
      'zh-cn': '日本',
      es: 'Japón',
      uk: 'Японія',
      ja: '日本',
      fa: 'ژاپن',
      pl: 'Japonia',
      nl: 'Japan',
      tr: 'Japonya'
    },
    name: 'Japan',
    phone_code: '81'
  },
  {
    code: 'JE',
    emoji: '🇯🇪',
    translations: {
      de: 'Jersey',
      ru: 'Джерси',
      pt: 'Jersey',
      'pt-BR': 'Jersey',
      kr: '저지 섬',
      hr: 'Jersey',
      it: 'Isola di Jersey',
      fr: 'Jersey',
      'zh-cn': '泽西岛',
      es: 'Jersey',
      uk: 'Джерсі',
      ja: 'ジャージー',
      fa: 'جرزی',
      pl: 'Jersey',
      nl: 'Jersey',
      tr: 'Jersey'
    },
    name: 'Jersey',
    phone_code: '44'
  },
  {
    code: 'JO',
    emoji: '🇯🇴',
    translations: {
      de: 'Jordanien',
      ru: 'Джордан',
      pt: 'Jordânia',
      'pt-BR': 'Jordânia',
      kr: '요르단',
      hr: 'Jordan',
      it: 'Giordania',
      fr: 'Jordanie',
      'zh-cn': '约旦',
      es: 'Jordania',
      uk: 'Йорданія',
      ja: 'ヨルダン',
      fa: 'اردن',
      pl: 'Jordan',
      nl: 'Jordanië',
      tr: 'Ürdün'
    },
    name: 'Jordan',
    phone_code: '962'
  },
  {
    code: 'KZ',
    emoji: '🇰🇿',
    translations: {
      de: 'Kasachstan',
      ru: 'Казахстан',
      pt: 'Cazaquistão',
      'pt-BR': 'Cazaquistão',
      kr: '카자흐스탄',
      hr: 'Kazahstan',
      it: 'Kazakistan',
      fr: 'Kazakhstan',
      'zh-cn': '哈萨克斯坦',
      es: 'Kazajistán',
      uk: 'Казахстан',
      ja: 'カザフスタン',
      fa: 'قزاقستان',
      pl: 'Kazachstan',
      nl: 'Kazachstan',
      tr: 'Kazakistan'
    },
    name: 'Kazakhstan',
    phone_code: '7'
  },
  {
    code: 'KE',
    emoji: '🇰🇪',
    translations: {
      de: 'Kenia',
      ru: 'Кения',
      pt: 'Quénia',
      'pt-BR': 'Quênia',
      kr: '케냐',
      hr: 'Kenija',
      it: 'Kenya',
      fr: 'Kenya',
      'zh-cn': '肯尼亚',
      es: 'Kenia',
      uk: 'Кенія',
      ja: 'ケニア',
      fa: 'کنیا',
      pl: 'Kenia',
      nl: 'Kenia',
      tr: 'Kenya'
    },
    name: 'Kenya',
    phone_code: '254'
  },
  {
    code: 'KI',
    emoji: '🇰🇮',
    translations: {
      de: 'Kiribati',
      ru: 'Кирибати',
      pt: 'Quiribáti',
      'pt-BR': 'Kiribati',
      kr: '키리바시',
      hr: 'Kiribati',
      it: 'Kiribati',
      fr: 'Kiribati',
      'zh-cn': '基里巴斯',
      es: 'Kiribati',
      uk: 'Кірібаті',
      ja: 'キリバス',
      fa: 'کیریباتی',
      pl: 'Kiribati',
      nl: 'Kiribati',
      tr: 'Kiribati'
    },
    name: 'Kiribati',
    phone_code: '686'
  },
  {
    code: 'XK',
    emoji: '🇽🇰',
    translations: {
      ru: 'Косово',
      uk: 'Косово',
      kr: '코소보',
      pl: 'Kosowo',
      'zh-cn': '科索沃',
      tr: 'Kosova'
    },
    name: 'Kosovo',
    phone_code: '383'
  },
  {
    code: 'KW',
    emoji: '🇰🇼',
    translations: {
      de: 'Kuwait',
      ru: 'Кувейт',
      pt: 'Kuwait',
      'pt-BR': 'Kuwait',
      kr: '쿠웨이트',
      hr: 'Kuvajt',
      it: 'Kuwait',
      fr: 'Koweït',
      'zh-cn': '科威特',
      es: 'Kuwait',
      uk: 'Кувейт',
      ja: 'クウェート',
      fa: 'کویت',
      pl: 'Kuwejt',
      nl: 'Koeweit',
      tr: 'Kuveyt'
    },
    name: 'Kuwait',
    phone_code: '965'
  },
  {
    code: 'KG',
    emoji: '🇰🇬',
    translations: {
      de: 'Kirgisistan',
      ru: 'Кыргызстан',
      pt: 'Quirguizistão',
      'pt-BR': 'Quirguistão',
      kr: '키르기스스탄',
      hr: 'Kirgistan',
      it: 'Kirghizistan',
      fr: 'Kirghizistan',
      'zh-cn': '吉尔吉斯斯坦',
      es: 'Kirguizistán',
      uk: 'Киргизстан',
      ja: 'キルギス',
      fa: 'قرقیزستان',
      pl: 'Kirgistan',
      nl: 'Kirgizië',
      tr: 'Kirgizistan'
    },
    name: 'Kyrgyzstan',
    phone_code: '996'
  },
  {
    code: 'LA',
    emoji: '🇱🇦',
    translations: {
      de: 'Laos',
      ru: 'Лаос',
      pt: 'Laos',
      'pt-BR': 'Laos',
      kr: '라오스',
      hr: 'Laos',
      it: 'Laos',
      fr: 'Laos',
      'zh-cn': '寮人民民主共和国',
      es: 'Laos',
      uk: 'Лаос',
      ja: 'ラオス人民民主共和国',
      fa: 'لائوس',
      pl: 'Laos',
      nl: 'Laos',
      tr: 'Laos'
    },
    name: 'Laos',
    phone_code: '856'
  },
  {
    code: 'LV',
    emoji: '🇱🇻',
    translations: {
      de: 'Lettland',
      ru: 'Латвия',
      pt: 'Letónia',
      'pt-BR': 'Letônia',
      kr: '라트비아',
      hr: 'Latvija',
      it: 'Lettonia',
      fr: 'Lettonie',
      'zh-cn': '拉脱维亚',
      es: 'Letonia',
      uk: 'Латвія',
      ja: 'ラトビア',
      fa: 'لتونی',
      pl: 'Łotwa',
      nl: 'Letland',
      tr: 'Letonya'
    },
    name: 'Latvia',
    phone_code: '371'
  },
  {
    code: 'LB',
    emoji: '🇱🇧',
    translations: {
      de: 'Libanon',
      ru: 'Ливан',
      pt: 'Líbano',
      'pt-BR': 'Líbano',
      kr: '레바논',
      hr: 'Libanon',
      it: 'Libano',
      fr: 'Liban',
      'zh-cn': '黎巴嫩',
      es: 'Líbano',
      uk: 'Ліван',
      ja: 'レバノン',
      fa: 'لبنان',
      pl: 'Liban',
      nl: 'Libanon',
      tr: 'Lübnan'
    },
    name: 'Lebanon',
    phone_code: '961'
  },
  {
    code: 'LS',
    emoji: '🇱🇸',
    translations: {
      de: 'Lesotho',
      ru: 'Лесото',
      pt: 'Lesoto',
      'pt-BR': 'Lesoto',
      kr: '레소토',
      hr: 'Lesoto',
      it: 'Lesotho',
      fr: 'Lesotho',
      'zh-cn': '莱索托',
      es: 'Lesotho',
      uk: 'Лесото',
      ja: 'レソト',
      fa: 'لسوتو',
      pl: 'Lesotho',
      nl: 'Lesotho',
      tr: 'Lesotho'
    },
    name: 'Lesotho',
    phone_code: '266'
  },
  {
    code: 'LR',
    emoji: '🇱🇷',
    translations: {
      de: 'Liberia',
      ru: 'Либерия',
      pt: 'Libéria',
      'pt-BR': 'Libéria',
      kr: '라이베리아',
      hr: 'Liberija',
      it: 'Liberia',
      fr: 'Liberia',
      'zh-cn': '利比里亚',
      es: 'Liberia',
      uk: 'Ліберія',
      ja: 'リベリア',
      fa: 'لیبریا',
      pl: 'Liberia',
      nl: 'Liberia',
      tr: 'Liberya'
    },
    name: 'Liberia',
    phone_code: '231'
  },
  {
    code: 'LY',
    emoji: '🇱🇾',
    translations: {
      de: 'Libyen',
      ru: 'Ливия',
      pt: 'Líbia',
      'pt-BR': 'Líbia',
      kr: '리비아',
      hr: 'Libija',
      it: 'Libia',
      fr: 'Libye',
      'zh-cn': '利比亚',
      es: 'Libia',
      uk: 'Лівія',
      ja: 'リビア',
      fa: 'لیبی',
      pl: 'Libia',
      nl: 'Libië',
      tr: 'Libya'
    },
    name: 'Libya',
    phone_code: '218'
  },
  {
    code: 'LI',
    emoji: '🇱🇮',
    translations: {
      de: 'Liechtenstein',
      ru: 'Лихтенштейн',
      pt: 'Listenstaine',
      'pt-BR': 'Liechtenstein',
      kr: '리히텐슈타인',
      hr: 'Lihtenštajn',
      it: 'Liechtenstein',
      fr: 'Liechtenstein',
      'zh-cn': '列支敦士登',
      es: 'Liechtenstein',
      uk: 'Ліхтенштейн',
      ja: 'リヒテンシュタイン',
      fa: 'لیختن‌اشتاین',
      pl: 'Liechtenstein',
      nl: 'Liechtenstein',
      tr: 'Lihtenştayn'
    },
    name: 'Liechtenstein',
    phone_code: '423'
  },
  {
    code: 'LT',
    emoji: '🇱🇹',
    translations: {
      de: 'Litauen',
      ru: 'Литва',
      pt: 'Lituânia',
      'pt-BR': 'Lituânia',
      kr: '리투아니아',
      hr: 'Litva',
      it: 'Lituania',
      fr: 'Lituanie',
      'zh-cn': '立陶宛',
      es: 'Lituania',
      uk: 'Литва',
      ja: 'リトアニア',
      fa: 'لیتوانی',
      pl: 'Litwa',
      nl: 'Litouwen',
      tr: 'Litvanya'
    },
    name: 'Lithuania',
    phone_code: '370'
  },
  {
    code: 'LU',
    emoji: '🇱🇺',
    translations: {
      de: 'Luxemburg',
      ru: 'Люксембург',
      pt: 'Luxemburgo',
      'pt-BR': 'Luxemburgo',
      kr: '룩셈부르크',
      hr: 'Luksemburg',
      it: 'Lussemburgo',
      fr: 'Luxembourg',
      'zh-cn': '卢森堡',
      es: 'Luxemburgo',
      uk: 'Люксембург',
      ja: 'ルクセンブルク',
      fa: 'لوکزامبورگ',
      pl: 'Luksemburg',
      nl: 'Luxemburg',
      tr: 'Lüksemburg'
    },
    name: 'Luxembourg',
    phone_code: '352'
  },
  {
    code: 'MO',
    emoji: '🇲🇴',
    translations: {
      de: 'Macao',
      ru: 'Макао С.А.Р.',
      pt: 'Macau',
      'pt-BR': 'Macau',
      kr: '마카오',
      hr: 'Makao',
      it: 'Macao',
      fr: 'Macao',
      'zh-cn': '中国澳门',
      es: 'Macao',
      uk: 'САР Макао.',
      ja: 'マカオ',
      fa: 'مکائو',
      pl: 'Macau S.A.R.',
      nl: 'Macao',
      tr: 'Makao'
    },
    name: 'Macau S.A.R.',
    phone_code: '853'
  },
  {
    code: 'MG',
    emoji: '🇲🇬',
    translations: {
      de: 'Madagaskar',
      ru: 'Мадагаскар',
      pt: 'Madagáscar',
      'pt-BR': 'Madagascar',
      kr: '마다가스카르',
      hr: 'Madagaskar',
      it: 'Madagascar',
      fr: 'Madagascar',
      'zh-cn': '马达加斯加',
      es: 'Madagascar',
      uk: 'Мадагаскар',
      ja: 'マダガスカル',
      fa: 'ماداگاسکار',
      pl: 'Madagaskar',
      nl: 'Madagaskar',
      tr: 'Madagaskar'
    },
    name: 'Madagascar',
    phone_code: '261'
  },
  {
    code: 'MW',
    emoji: '🇲🇼',
    translations: {
      de: 'Malawi',
      ru: 'Малави',
      pt: 'Malávi',
      'pt-BR': 'Malawi',
      kr: '말라위',
      hr: 'Malavi',
      it: 'Malawi',
      fr: 'Malawi',
      'zh-cn': '马拉维',
      es: 'Malawi',
      uk: 'Малаві',
      ja: 'マラウイ',
      fa: 'مالاوی',
      pl: 'Malawi',
      nl: 'Malawi',
      tr: 'Malavi'
    },
    name: 'Malawi',
    phone_code: '265'
  },
  {
    code: 'MY',
    emoji: '🇲🇾',
    translations: {
      de: 'Malaysia',
      ru: 'Малайзия',
      pt: 'Malásia',
      'pt-BR': 'Malásia',
      kr: '말레이시아',
      hr: 'Malezija',
      it: 'Malesia',
      fr: 'Malaisie',
      'zh-cn': '马来西亚',
      es: 'Malasia',
      uk: 'Малайзія',
      ja: 'マレーシア',
      fa: 'مالزی',
      pl: 'Malezja',
      nl: 'Maleisië',
      tr: 'Malezya'
    },
    name: 'Malaysia',
    phone_code: '60'
  },
  {
    code: 'MV',
    emoji: '🇲🇻',
    translations: {
      de: 'Malediven',
      ru: 'Мальдивы',
      pt: 'Maldivas',
      'pt-BR': 'Maldivas',
      kr: '몰디브',
      hr: 'Maldivi',
      it: 'Maldive',
      fr: 'Maldives',
      'zh-cn': '马尔代夫',
      es: 'Maldivas',
      uk: 'Мальдіви',
      ja: 'モルディブ',
      fa: 'مالدیو',
      pl: 'Malediwy',
      nl: 'Maldiven',
      tr: 'Maldivler'
    },
    name: 'Maldives',
    phone_code: '960'
  },
  {
    code: 'ML',
    emoji: '🇲🇱',
    translations: {
      de: 'Mali',
      ru: 'Мали',
      pt: 'Mali',
      'pt-BR': 'Mali',
      kr: '말리',
      hr: 'Mali',
      it: 'Mali',
      fr: 'Mali',
      'zh-cn': '马里',
      es: 'Mali',
      uk: 'Малі',
      ja: 'マリ',
      fa: 'مالی',
      pl: 'Mali',
      nl: 'Mali',
      tr: 'Mali'
    },
    name: 'Mali',
    phone_code: '223'
  },
  {
    code: 'MT',
    emoji: '🇲🇹',
    translations: {
      de: 'Malta',
      ru: 'Мальта',
      pt: 'Malta',
      'pt-BR': 'Malta',
      kr: '몰타',
      hr: 'Malta',
      it: 'Malta',
      fr: 'Malte',
      'zh-cn': '马耳他',
      es: 'Malta',
      uk: 'Мальта',
      ja: 'マルタ',
      fa: 'مالت',
      pl: 'Malta',
      nl: 'Malta',
      tr: 'Malta'
    },
    name: 'Malta',
    phone_code: '356'
  },
  {
    code: 'IM',
    emoji: '🇮🇲',
    translations: {
      de: 'Insel Man',
      ru: 'Мэн (остров)',
      pt: 'Ilha de Man',
      'pt-BR': 'Ilha de Man',
      kr: '맨 섬',
      hr: 'Otok Man',
      it: 'Isola di Man',
      fr: 'Île de Man',
      'zh-cn': '马恩岛',
      es: 'Isla de Man',
      uk: 'Мен (острів Мен)',
      ja: 'マン島',
      fa: 'جزیره من',
      pl: 'Man (Wyspa)',
      nl: 'Isle of Man',
      tr: 'Man Adasi'
    },
    name: 'Man (Isle of)',
    phone_code: '44'
  },
  {
    code: 'MH',
    emoji: '🇲🇭',
    translations: {
      de: 'Marshallinseln',
      ru: 'Маршалловы острова',
      pt: 'Ilhas Marshall',
      'pt-BR': 'Ilhas Marshall',
      kr: '마셜 제도',
      hr: 'Maršalovi Otoci',
      it: 'Isole Marshall',
      fr: 'Îles Marshall',
      'zh-cn': '马绍尔群岛',
      es: 'Islas Marshall',
      uk: 'Маршаллові острови',
      ja: 'マーシャル諸島',
      fa: 'جزایر مارشال',
      pl: 'Wyspy Marshalla',
      nl: 'Marshalleilanden',
      tr: 'Marşal Adalari'
    },
    name: 'Marshall Islands',
    phone_code: '692'
  },
  {
    code: 'MQ',
    emoji: '🇲🇶',
    translations: {
      de: 'Martinique',
      ru: 'Мартиника',
      pt: 'Martinica',
      'pt-BR': 'Martinica',
      kr: '마르티니크',
      hr: 'Martinique',
      it: 'Martinica',
      fr: 'Martinique',
      'zh-cn': '马提尼克岛',
      es: 'Martinica',
      uk: 'Мартініка',
      ja: 'マルティニーク',
      fa: 'مونتسرات',
      pl: 'Martynika',
      nl: 'Martinique',
      tr: 'Martinik'
    },
    name: 'Martinique',
    phone_code: '596'
  },
  {
    code: 'MR',
    emoji: '🇲🇷',
    translations: {
      de: 'Mauretanien',
      ru: 'Мавритания',
      pt: 'Mauritânia',
      'pt-BR': 'Mauritânia',
      kr: '모리타니',
      hr: 'Mauritanija',
      it: 'Mauritania',
      fr: 'Mauritanie',
      'zh-cn': '毛里塔尼亚',
      es: 'Mauritania',
      uk: 'Мавританія',
      ja: 'モーリタニア',
      fa: 'موریتانی',
      pl: 'Mauretania',
      nl: 'Mauritanië',
      tr: 'Moritanya'
    },
    name: 'Mauritania',
    phone_code: '222'
  },
  {
    code: 'MU',
    emoji: '🇲🇺',
    translations: {
      de: 'Mauritius',
      ru: 'Маврикий',
      pt: 'Maurícia',
      'pt-BR': 'Maurício',
      kr: '모리셔스',
      hr: 'Mauricijus',
      it: 'Mauritius',
      fr: 'Île Maurice',
      'zh-cn': '毛里求斯',
      es: 'Mauricio',
      uk: 'Маврикій',
      ja: 'モーリシャス',
      fa: 'موریس',
      pl: 'Mauritius',
      nl: 'Mauritius',
      tr: 'Morityus'
    },
    name: 'Mauritius',
    phone_code: '230'
  },
  {
    code: 'YT',
    emoji: '🇾🇹',
    translations: {
      de: 'Mayotte',
      ru: 'Майотта',
      pt: 'Mayotte',
      'pt-BR': 'Mayotte',
      kr: '마요트',
      hr: 'Mayotte',
      it: 'Mayotte',
      fr: 'Mayotte',
      'zh-cn': '马约特',
      es: 'Mayotte',
      uk: 'Майотта',
      ja: 'マヨット',
      fa: 'مایوت',
      pl: 'Majotta',
      nl: 'Mayotte',
      tr: 'Mayotte'
    },
    name: 'Mayotte',
    phone_code: '262'
  },
  {
    code: 'MX',
    emoji: '🇲🇽',
    translations: {
      de: 'Mexiko',
      ru: 'Мексика',
      pt: 'México',
      'pt-BR': 'México',
      kr: '멕시코',
      hr: 'Meksiko',
      it: 'Messico',
      fr: 'Mexique',
      'zh-cn': '墨西哥',
      es: 'México',
      uk: 'Мексика',
      ja: 'メキシコ',
      fa: 'مکزیک',
      pl: 'Meksyk',
      nl: 'Mexico',
      tr: 'Meksika'
    },
    name: 'Mexico',
    phone_code: '52'
  },
  {
    code: 'FM',
    emoji: '🇫🇲',
    translations: {
      de: 'Mikronesien',
      ru: 'Микронезия',
      pt: 'Micronésia',
      'pt-BR': 'Micronésia',
      kr: '미크로네시아 연방',
      hr: 'Mikronezija',
      it: 'Micronesia',
      fr: 'Micronésie',
      'zh-cn': '密克罗尼西亚',
      es: 'Micronesia',
      uk: 'Мікронезія',
      ja: 'ミクロネシア連邦',
      fa: 'ایالات فدرال میکرونزی',
      pl: 'Mikronezja',
      nl: 'Micronesië',
      tr: 'Mikronezya'
    },
    name: 'Micronesia',
    phone_code: '691'
  },
  {
    code: 'MD',
    emoji: '🇲🇩',
    translations: {
      de: 'Moldawie',
      ru: 'Молдова',
      pt: 'Moldávia',
      'pt-BR': 'Moldávia',
      kr: '몰도바',
      hr: 'Moldova',
      it: 'Moldavia',
      fr: 'Moldavie',
      'zh-cn': '摩尔多瓦',
      es: 'Moldavia',
      uk: 'Молдова',
      ja: 'モルドバ共和国',
      fa: 'مولداوی',
      pl: 'Mołdawia',
      nl: 'Moldavië',
      tr: 'Moldova'
    },
    name: 'Moldova',
    phone_code: '373'
  },
  {
    code: 'MC',
    emoji: '🇲🇨',
    translations: {
      de: 'Monaco',
      ru: 'Монако',
      pt: 'Mónaco',
      'pt-BR': 'Mônaco',
      kr: '모나코',
      hr: 'Monako',
      it: 'Principato di Monaco',
      fr: 'Monaco',
      'zh-cn': '摩纳哥',
      es: 'Mónaco',
      uk: 'Монако',
      ja: 'モナコ',
      fa: 'موناکو',
      pl: 'Monako',
      nl: 'Monaco',
      tr: 'Monako'
    },
    name: 'Monaco',
    phone_code: '377'
  },
  {
    code: 'MN',
    emoji: '🇲🇳',
    translations: {
      de: 'Mongolei',
      ru: 'Монголия',
      pt: 'Mongólia',
      'pt-BR': 'Mongólia',
      kr: '몽골',
      hr: 'Mongolija',
      it: 'Mongolia',
      fr: 'Mongolie',
      'zh-cn': '蒙古',
      es: 'Mongolia',
      uk: 'Монголія',
      ja: 'モンゴル',
      fa: 'مغولستان',
      pl: 'Mongolia',
      nl: 'Mongolië',
      tr: 'Moğolistan'
    },
    name: 'Mongolia',
    phone_code: '976'
  },
  {
    code: 'ME',
    emoji: '🇲🇪',
    translations: {
      de: 'Montenegro',
      ru: 'Черногория',
      pt: 'Montenegro',
      'pt-BR': 'Montenegro',
      kr: '몬테네그로',
      hr: 'Crna Gora',
      it: 'Montenegro',
      fr: 'Monténégro',
      'zh-cn': '黑山',
      es: 'Montenegro',
      uk: 'Чорногорія',
      ja: 'モンテネグロ',
      fa: 'مونته‌نگرو',
      pl: 'Czarnogóra',
      nl: 'Montenegro',
      tr: 'Karadağ'
    },
    name: 'Montenegro',
    phone_code: '382'
  },
  {
    code: 'MS',
    emoji: '🇲🇸',
    translations: {
      de: 'Montserrat',
      ru: 'Монтсеррат',
      pt: 'Monserrate',
      'pt-BR': 'Montserrat',
      kr: '몬트세랫',
      hr: 'Montserrat',
      it: 'Montserrat',
      fr: 'Montserrat',
      'zh-cn': '蒙特塞拉特',
      es: 'Montserrat',
      uk: 'Монтсеррат',
      ja: 'モントセラト',
      fa: 'مایوت',
      pl: 'Montserrat',
      nl: 'Montserrat',
      tr: 'Montserrat'
    },
    name: 'Montserrat',
    phone_code: '1'
  },
  {
    code: 'MA',
    emoji: '🇲🇦',
    translations: {
      de: 'Marokko',
      ru: 'Марокко',
      pt: 'Marrocos',
      'pt-BR': 'Marrocos',
      kr: '모로코',
      hr: 'Maroko',
      it: 'Marocco',
      fr: 'Maroc',
      'zh-cn': '摩洛哥',
      es: 'Marruecos',
      uk: 'Марокко',
      ja: 'モロッコ',
      fa: 'مراکش',
      pl: 'Maroko',
      nl: 'Marokko',
      tr: 'Fas'
    },
    name: 'Morocco',
    phone_code: '212'
  },
  {
    code: 'MZ',
    emoji: '🇲🇿',
    translations: {
      de: 'Mosambik',
      ru: 'Мозамбик',
      pt: 'Moçambique',
      'pt-BR': 'Moçambique',
      kr: '모잠비크',
      hr: 'Mozambik',
      it: 'Mozambico',
      fr: 'Mozambique',
      'zh-cn': '莫桑比克',
      es: 'Mozambique',
      uk: 'Мозамбік',
      ja: 'モザンビーク',
      fa: 'موزامبیک',
      pl: 'Mozambik',
      nl: 'Mozambique',
      tr: 'Mozambik'
    },
    name: 'Mozambique',
    phone_code: '258'
  },
  {
    code: 'MM',
    emoji: '🇲🇲',
    translations: {
      de: 'Myanmar',
      ru: 'Мьянма',
      pt: 'Myanmar',
      'pt-BR': 'Myanmar',
      kr: '미얀마',
      hr: 'Mijanmar',
      it: 'Birmania',
      fr: 'Myanmar',
      'zh-cn': '缅甸',
      es: 'Myanmar',
      uk: "М'янма",
      ja: 'ミャンマー',
      fa: 'میانمار',
      pl: 'Birma',
      nl: 'Myanmar',
      tr: 'Myanmar'
    },
    name: 'Myanmar',
    phone_code: '95'
  },
  {
    code: 'NA',
    emoji: '🇳🇦',
    translations: {
      de: 'Namibia',
      ru: 'Намибия',
      pt: 'Namíbia',
      'pt-BR': 'Namíbia',
      kr: '나미비아',
      hr: 'Namibija',
      it: 'Namibia',
      fr: 'Namibie',
      'zh-cn': '纳米比亚',
      es: 'Namibia',
      uk: 'Намібія',
      ja: 'ナミビア',
      fa: 'نامیبیا',
      pl: 'Namibia',
      nl: 'Namibië',
      tr: 'Namibya'
    },
    name: 'Namibia',
    phone_code: '264'
  },
  {
    code: 'NR',
    emoji: '🇳🇷',
    translations: {
      de: 'Nauru',
      ru: 'Науру',
      pt: 'Nauru',
      'pt-BR': 'Nauru',
      kr: '나우루',
      hr: 'Nauru',
      it: 'Nauru',
      fr: 'Nauru',
      'zh-cn': '瑙鲁',
      es: 'Nauru',
      uk: 'Науру',
      ja: 'ナウル',
      fa: 'نائورو',
      pl: 'Nauru',
      nl: 'Nauru',
      tr: 'Nauru'
    },
    name: 'Nauru',
    phone_code: '674'
  },
  {
    code: 'NP',
    emoji: '🇳🇵',
    translations: {
      de: 'Népal',
      ru: 'Непал',
      pt: 'Nepal',
      'pt-BR': 'Nepal',
      kr: '네팔',
      hr: 'Nepal',
      it: 'Nepal',
      fr: 'Népal',
      'zh-cn': '尼泊尔',
      es: 'Nepal',
      uk: 'Непал',
      ja: 'ネパール',
      fa: 'نپال',
      pl: 'Nepal',
      nl: 'Nepal',
      tr: 'Nepal'
    },
    name: 'Nepal',
    phone_code: '977'
  },
  {
    code: 'NL',
    emoji: '🇳🇱',
    translations: {
      de: 'Niederlande',
      ru: 'Нидерланды',
      pt: 'Países Baixos',
      'pt-BR': 'Holanda',
      kr: '네덜란드 ',
      hr: 'Nizozemska',
      it: 'Paesi Bassi',
      fr: 'Pays-Bas',
      'zh-cn': '荷兰',
      es: 'Países Bajos',
      uk: 'Нідерланди',
      ja: 'オランダ',
      fa: 'پادشاهی هلند',
      pl: 'Holandia',
      nl: 'Nederland',
      tr: 'Hollanda'
    },
    name: 'Netherlands',
    phone_code: '31'
  },
  {
    code: 'NC',
    emoji: '🇳🇨',
    translations: {
      de: 'Neukaledonien',
      ru: 'Новая Каледония',
      pt: 'Nova Caledónia',
      'pt-BR': 'Nova Caledônia',
      kr: '누벨칼레도니',
      hr: 'Nova Kaledonija',
      it: 'Nuova Caledonia',
      fr: 'Nouvelle-Calédonie',
      'zh-cn': '新喀里多尼亚',
      es: 'Nueva Caledonia',
      uk: 'Нова Каледонія',
      ja: 'ニューカレドニア',
      fa: 'کالدونیای جدید',
      pl: 'Nowa Kaledonia',
      nl: 'Nieuw-Caledonië',
      tr: 'Yeni Kaledonya'
    },
    name: 'New Caledonia',
    phone_code: '687'
  },
  {
    code: 'NZ',
    emoji: '🇳🇿',
    translations: {
      de: 'Neuseeland',
      ru: 'Новая Зеландия',
      pt: 'Nova Zelândia',
      'pt-BR': 'Nova Zelândia',
      kr: '뉴질랜드',
      hr: 'Novi Zeland',
      it: 'Nuova Zelanda',
      fr: 'Nouvelle-Zélande',
      'zh-cn': '新西兰',
      es: 'Nueva Zelanda',
      uk: 'Нова Зеландія',
      ja: 'ニュージーランド',
      fa: 'نیوزیلند',
      pl: 'Nowa Zelandia',
      nl: 'Nieuw-Zeeland',
      tr: 'Yeni Zelanda'
    },
    name: 'New Zealand',
    phone_code: '64'
  },
  {
    code: 'NI',
    emoji: '🇳🇮',
    translations: {
      de: 'Nicaragua',
      ru: 'Никарагуа',
      pt: 'Nicarágua',
      'pt-BR': 'Nicarágua',
      kr: '니카라과',
      hr: 'Nikaragva',
      it: 'Nicaragua',
      fr: 'Nicaragua',
      'zh-cn': '尼加拉瓜',
      es: 'Nicaragua',
      uk: 'Нікарагуа',
      ja: 'ニカラグア',
      fa: 'نیکاراگوئه',
      pl: 'Nikaragua',
      nl: 'Nicaragua',
      tr: 'Nikaragua'
    },
    name: 'Nicaragua',
    phone_code: '505'
  },
  {
    code: 'NE',
    emoji: '🇳🇪',
    translations: {
      de: 'Niger',
      ru: 'Нигер',
      pt: 'Níger',
      'pt-BR': 'Níger',
      kr: '니제르',
      hr: 'Niger',
      it: 'Niger',
      fr: 'Niger',
      'zh-cn': '尼日尔',
      es: 'Níger',
      uk: 'Нігер',
      ja: 'ニジェール',
      fa: 'نیجر',
      pl: 'Niger',
      nl: 'Niger',
      tr: 'Nijer'
    },
    name: 'Niger',
    phone_code: '227'
  },
  {
    code: 'NG',
    emoji: '🇳🇬',
    translations: {
      de: 'Nigeria',
      ru: 'Нигерия',
      pt: 'Nigéria',
      'pt-BR': 'Nigéria',
      kr: '나이지리아',
      hr: 'Nigerija',
      it: 'Nigeria',
      fr: 'Nigéria',
      'zh-cn': '尼日利亚',
      es: 'Nigeria',
      uk: 'Нігерія',
      ja: 'ナイジェリア',
      fa: 'نیجریه',
      pl: 'Nigeria',
      nl: 'Nigeria',
      tr: 'Nijerya'
    },
    name: 'Nigeria',
    phone_code: '234'
  },
  {
    code: 'NU',
    emoji: '🇳🇺',
    translations: {
      de: 'Niue',
      ru: 'Ниуэ',
      pt: 'Niue',
      'pt-BR': 'Niue',
      kr: '니우에',
      hr: 'Niue',
      it: 'Niue',
      fr: 'Niue',
      'zh-cn': '纽埃',
      es: 'Niue',
      uk: 'Ніуе',
      ja: 'ニウエ',
      fa: 'نیووی',
      pl: 'Niue',
      nl: 'Niue',
      tr: 'Niue'
    },
    name: 'Niue',
    phone_code: '683'
  },
  {
    code: 'NF',
    emoji: '🇳🇫',
    translations: {
      de: 'Norfolkinsel',
      ru: 'Остров Норфолк',
      pt: 'Ilha Norfolk',
      'pt-BR': 'Ilha Norfolk',
      kr: '노퍽 섬',
      hr: 'Otok Norfolk',
      it: 'Isola Norfolk',
      fr: 'Île de Norfolk',
      'zh-cn': '诺福克岛',
      es: 'Isla de Norfolk',
      uk: 'Острів Норфолк',
      ja: 'ノーフォーク島',
      fa: 'جزیره نورفک',
      pl: 'Wyspa Norfolk',
      nl: 'Norfolkeiland',
      tr: 'Norfolk Adasi'
    },
    name: 'Norfolk Island',
    phone_code: '672'
  },
  {
    code: 'KP',
    emoji: '🇰🇵',
    translations: {
      de: 'Nordkorea',
      ru: 'Северная Корея',
      pt: 'Coreia do Norte',
      'pt-BR': 'Coreia do Norte',
      kr: '조선민주주의인민공화국',
      hr: 'Sjeverna Koreja',
      it: 'Corea del Nord',
      fr: 'Corée du Nord',
      'zh-cn': '朝鲜',
      es: 'Corea del Norte',
      uk: 'Північна Корея',
      ja: '朝鮮民主主義人民共和国',
      fa: 'کره جنوبی',
      pl: 'Korea Północna',
      nl: 'Noord-Korea',
      tr: 'Kuzey Kore'
    },
    name: 'North Korea',
    phone_code: '850'
  },
  {
    code: 'MK',
    emoji: '🇲🇰',
    translations: {
      de: 'Nordmazedonien',
      ru: 'Северная Македония',
      pt: 'Macedónia do Norte',
      'pt-BR': 'Macedônia do Norte',
      kr: '북마케도니아',
      hr: 'Sjeverna Makedonija',
      it: 'Macedonia del Nord',
      fr: 'Macédoine du Nord',
      'zh-cn': '北馬其頓',
      es: 'Macedonia del Norte',
      uk: 'Північна Македонія',
      ja: '北マケドニア',
      fa: 'ﻢﻗﺩﻮﻨﯿﻫ ﺶﻣﺎﻠﯾ',
      pl: 'Macedonia Północna',
      nl: 'Noord-Macedonië',
      tr: 'Kuzey Makedonya'
    },
    name: 'North Macedonia',
    phone_code: '389'
  },
  {
    code: 'MP',
    emoji: '🇲🇵',
    translations: {
      de: 'Nördliche Marianen',
      ru: 'Северные Марианские острова',
      pt: 'Ilhas Marianas',
      'pt-BR': 'Ilhas Marianas',
      kr: '북마리아나 제도',
      hr: 'Sjevernomarijanski otoci',
      it: 'Isole Marianne Settentrionali',
      fr: 'Îles Mariannes du Nord',
      'zh-cn': '北马里亚纳群岛',
      es: 'Islas Marianas del Norte',
      uk: 'Північні Маріанські острови',
      ja: '北マリアナ諸島',
      fa: 'جزایر ماریانای شمالی',
      pl: 'Mariany Północne',
      nl: 'Noordelijke Marianeneilanden',
      tr: 'Kuzey Mariana Adalari'
    },
    name: 'Northern Mariana Islands',
    phone_code: '1'
  },
  {
    code: 'NO',
    emoji: '🇳🇴',
    translations: {
      de: 'Norwegen',
      ru: 'Норвегия',
      pt: 'Noruega',
      'pt-BR': 'Noruega',
      kr: '노르웨이',
      hr: 'Norveška',
      it: 'Norvegia',
      fr: 'Norvège',
      'zh-cn': '挪威',
      es: 'Noruega',
      uk: 'Норвегія',
      ja: 'ノルウェー',
      fa: 'نروژ',
      pl: 'Norwegia',
      nl: 'Noorwegen',
      tr: 'Norveç'
    },
    name: 'Norway',
    phone_code: '47'
  },
  {
    code: 'OM',
    emoji: '🇴🇲',
    translations: {
      de: 'Oman',
      ru: 'Оман',
      pt: 'Omã',
      'pt-BR': 'Omã',
      kr: '오만',
      hr: 'Oman',
      it: 'oman',
      fr: 'Oman',
      'zh-cn': '阿曼',
      es: 'Omán',
      uk: 'Оман',
      ja: 'オマーン',
      fa: 'عمان',
      pl: 'Oman',
      nl: 'Oman',
      tr: 'Umman'
    },
    name: 'Oman',
    phone_code: '968'
  },
  {
    code: 'PK',
    emoji: '🇵🇰',
    translations: {
      de: 'Pakistan',
      ru: 'Пакистан',
      pt: 'Paquistão',
      'pt-BR': 'Paquistão',
      kr: '파키스탄',
      hr: 'Pakistan',
      it: 'Pakistan',
      fr: 'Pakistan',
      'zh-cn': '巴基斯坦',
      es: 'Pakistán',
      uk: 'Пакистан',
      ja: 'パキスタン',
      fa: 'پاکستان',
      pl: 'Pakistan',
      nl: 'Pakistan',
      tr: 'Pakistan'
    },
    name: 'Pakistan',
    phone_code: '92'
  },
  {
    code: 'PW',
    emoji: '🇵🇼',
    translations: {
      de: 'Palau',
      ru: 'Палау',
      pt: 'Palau',
      'pt-BR': 'Palau',
      kr: '팔라우',
      hr: 'Palau',
      it: 'Palau',
      fr: 'Palaos',
      'zh-cn': '帕劳',
      es: 'Palau',
      uk: 'Палау',
      ja: 'パラオ',
      fa: 'پالائو',
      pl: 'Palau',
      nl: 'Palau',
      tr: 'Palau'
    },
    name: 'Palau',
    phone_code: '680'
  },
  {
    code: 'PS',
    emoji: '🇵🇸',
    translations: {
      de: 'Palästina',
      ru: 'Оккупированная палестинская территория',
      pt: 'Palestina',
      'pt-BR': 'Palestina',
      kr: '팔레스타인 영토',
      hr: 'Palestina',
      it: 'Palestina',
      fr: 'Palestine',
      'zh-cn': '巴勒斯坦',
      es: 'Palestina',
      uk: 'Окупована палестинська територія',
      ja: 'パレスチナ',
      fa: 'فلسطین',
      pl: 'Okupowane terytorium palestyńskie',
      nl: 'Palestijnse gebieden',
      tr: 'Filistin'
    },
    name: 'Palestinian Territory Occupied',
    phone_code: '970'
  },
  {
    code: 'PA',
    emoji: '🇵🇦',
    translations: {
      de: 'Panama',
      ru: 'Панама',
      pt: 'Panamá',
      'pt-BR': 'Panamá',
      kr: '파나마',
      hr: 'Panama',
      it: 'Panama',
      fr: 'Panama',
      'zh-cn': '巴拿马',
      es: 'Panamá',
      uk: 'Панама',
      ja: 'パナマ',
      fa: 'پاناما',
      pl: 'Panama',
      nl: 'Panama',
      tr: 'Panama'
    },
    name: 'Panama',
    phone_code: '507'
  },
  {
    code: 'PG',
    emoji: '🇵🇬',
    translations: {
      de: 'Papua-Neuguinea',
      ru: 'Папуа - Новая Гвинея',
      pt: 'Papua Nova Guiné',
      'pt-BR': 'Papua Nova Guiné',
      kr: '파푸아뉴기니',
      hr: 'Papua Nova Gvineja',
      it: 'Papua Nuova Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      'zh-cn': '巴布亚新几内亚',
      es: 'Papúa Nueva Guinea',
      uk: 'Папуа-Нова Гвінея',
      ja: 'パプアニューギニア',
      fa: 'پاپوآ گینه نو',
      pl: 'Papua-Nowa Gwinea',
      nl: 'Papoea-Nieuw-Guinea',
      tr: 'Papua Yeni Gine'
    },
    name: 'Papua New Guinea',
    phone_code: '675'
  },
  {
    code: 'PY',
    emoji: '🇵🇾',
    translations: {
      de: 'Paraguay',
      ru: 'Парагвай',
      pt: 'Paraguai',
      'pt-BR': 'Paraguai',
      kr: '파라과이',
      hr: 'Paragvaj',
      it: 'Paraguay',
      fr: 'Paraguay',
      'zh-cn': '巴拉圭',
      es: 'Paraguay',
      uk: 'Парагвай',
      ja: 'パラグアイ',
      fa: 'پاراگوئه',
      pl: 'Paragwaj',
      nl: 'Paraguay',
      tr: 'Paraguay'
    },
    name: 'Paraguay',
    phone_code: '595'
  },
  {
    code: 'PE',
    emoji: '🇵🇪',
    translations: {
      de: 'Peru',
      ru: 'Перу',
      pt: 'Peru',
      'pt-BR': 'Peru',
      kr: '페루',
      hr: 'Peru',
      it: 'Perù',
      fr: 'Pérou',
      'zh-cn': '秘鲁',
      es: 'Perú',
      uk: 'Перу',
      ja: 'ペルー',
      fa: 'پرو',
      pl: 'Peru',
      nl: 'Peru',
      tr: 'Peru'
    },
    name: 'Peru',
    phone_code: '51'
  },
  {
    code: 'PH',
    emoji: '🇵🇭',
    translations: {
      de: 'Philippinen',
      ru: 'Филиппины',
      pt: 'Filipinas',
      'pt-BR': 'Filipinas',
      kr: '필리핀',
      hr: 'Filipini',
      it: 'Filippine',
      fr: 'Philippines',
      'zh-cn': '菲律宾',
      es: 'Filipinas',
      uk: 'Філіппіни',
      ja: 'フィリピン',
      fa: 'جزایر الندفیلیپین',
      pl: 'Filipiny',
      nl: 'Filipijnen',
      tr: 'Filipinler'
    },
    name: 'Philippines',
    phone_code: '63'
  },
  {
    code: 'PN',
    emoji: '🇵🇳',
    translations: {
      de: 'Pitcairn',
      ru: 'Остров Питкэрн',
      pt: 'Ilhas Picárnia',
      'pt-BR': 'Ilhas Pitcairn',
      kr: '핏케언 제도',
      hr: 'Pitcairnovo otočje',
      it: 'Isole Pitcairn',
      fr: 'Îles Pitcairn',
      'zh-cn': '皮特凯恩群岛',
      es: 'Islas Pitcairn',
      uk: 'Острів Піткерн',
      ja: 'ピトケアン',
      fa: 'پیتکرن',
      pl: 'Wyspa Pitcairn',
      nl: 'Pitcairneilanden',
      tr: 'Pitcairn Adalari'
    },
    name: 'Pitcairn Island',
    phone_code: '870'
  },
  {
    code: 'PL',
    emoji: '🇵🇱',
    translations: {
      de: 'Polen',
      ru: 'Польша',
      pt: 'Polónia',
      'pt-BR': 'Polônia',
      kr: '폴란드',
      hr: 'Poljska',
      it: 'Polonia',
      fr: 'Pologne',
      'zh-cn': '波兰',
      es: 'Polonia',
      uk: 'Польща',
      ja: 'ポーランド',
      fa: 'لهستان',
      pl: 'Polska',
      nl: 'Polen',
      tr: 'Polonya'
    },
    name: 'Poland',
    phone_code: '48'
  },
  {
    code: 'PT',
    emoji: '🇵🇹',
    translations: {
      de: 'Portugal',
      ru: 'Португалия',
      pt: 'Portugal',
      'pt-BR': 'Portugal',
      kr: '포르투갈',
      hr: 'Portugal',
      it: 'Portogallo',
      fr: 'Portugal',
      'zh-cn': '葡萄牙',
      es: 'Portugal',
      uk: 'Португалія',
      ja: 'ポルトガル',
      fa: 'پرتغال',
      pl: 'Portugalia',
      nl: 'Portugal',
      tr: 'Portekiz'
    },
    name: 'Portugal',
    phone_code: '351'
  },
  {
    code: 'PR',
    emoji: '🇵🇷',
    translations: {
      de: 'Puerto Rico',
      ru: 'Пуэрто-Рико',
      pt: 'Porto Rico',
      'pt-BR': 'Porto Rico',
      kr: '푸에르토리코',
      hr: 'Portoriko',
      it: 'Porto Rico',
      fr: 'Porto Rico',
      'zh-cn': '波多黎各',
      es: 'Puerto Rico',
      uk: 'Пуерто-Ріко',
      ja: 'プエルトリコ',
      fa: 'پورتو ریکو',
      pl: 'Portoryko',
      nl: 'Puerto Rico',
      tr: 'Porto Riko'
    },
    name: 'Puerto Rico',
    phone_code: '1'
  },
  {
    code: 'QA',
    emoji: '🇶🇦',
    translations: {
      de: 'Katar',
      ru: 'Катар',
      pt: 'Catar',
      'pt-BR': 'Catar',
      kr: '카타르',
      hr: 'Katar',
      it: 'Qatar',
      fr: 'Qatar',
      'zh-cn': '卡塔尔',
      es: 'Catar',
      uk: 'Катар',
      ja: 'カタール',
      fa: 'قطر',
      pl: 'Katar',
      nl: 'Qatar',
      tr: 'Katar'
    },
    name: 'Qatar',
    phone_code: '974'
  },
  {
    code: 'RE',
    emoji: '🇷🇪',
    translations: {
      de: 'Réunion',
      ru: 'Воссоединение',
      pt: 'Reunião',
      'pt-BR': 'Reunião',
      kr: '레위니옹',
      hr: 'Réunion',
      it: 'Riunione',
      fr: 'Réunion',
      'zh-cn': '留尼汪岛',
      es: 'Reunión',
      uk: "Возз'єднання",
      ja: 'レユニオン',
      fa: 'رئونیون',
      pl: 'Reunion',
      nl: 'Réunion',
      tr: 'Réunion'
    },
    name: 'Reunion',
    phone_code: '262'
  },
  {
    code: 'RO',
    emoji: '🇷🇴',
    translations: {
      de: 'Rumänien',
      ru: 'Румыния',
      pt: 'Roménia',
      'pt-BR': 'Romênia',
      kr: '루마니아',
      hr: 'Rumunjska',
      it: 'Romania',
      fr: 'Roumanie',
      'zh-cn': '罗马尼亚',
      es: 'Rumania',
      uk: 'Румунія',
      ja: 'ルーマニア',
      fa: 'رومانی',
      pl: 'Rumunia',
      nl: 'Roemenië',
      tr: 'Romanya'
    },
    name: 'Romania',
    phone_code: '40'
  },
  {
    code: 'RU',
    emoji: '🇷🇺',
    translations: {
      de: 'Russland',
      ru: 'Россия',
      pt: 'Rússia',
      'pt-BR': 'Rússia',
      kr: '러시아',
      hr: 'Rusija',
      it: 'Russia',
      fr: 'Russie',
      'zh-cn': '俄罗斯联邦',
      es: 'Rusia',
      uk: 'Росія',
      ja: 'ロシア連邦',
      fa: 'روسیه',
      pl: 'Rosja',
      nl: 'Rusland',
      tr: 'Rusya'
    },
    name: 'Russia',
    phone_code: '7'
  },
  {
    code: 'RW',
    emoji: '🇷🇼',
    translations: {
      de: 'Ruanda',
      ru: 'Руанда',
      pt: 'Ruanda',
      'pt-BR': 'Ruanda',
      kr: '르완다',
      hr: 'Ruanda',
      it: 'Ruanda',
      fr: 'Rwanda',
      'zh-cn': '卢旺达',
      es: 'Ruanda',
      uk: 'Руанда',
      ja: 'ルワンダ',
      fa: 'رواندا',
      pl: 'Rwanda',
      nl: 'Rwanda',
      tr: 'Ruanda'
    },
    name: 'Rwanda',
    phone_code: '250'
  },
  {
    code: 'SH',
    emoji: '🇸🇭',
    translations: {
      de: 'Sankt Helena',
      ru: 'Святая Елена',
      pt: 'Santa Helena',
      'pt-BR': 'Santa Helena',
      kr: '세인트헬레나',
      hr: 'Sveta Helena',
      it: "Sant'Elena",
      fr: 'Sainte-Hélène',
      'zh-cn': '圣赫勒拿',
      es: 'Santa Helena',
      uk: 'Свята Єлена',
      ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
      fa: 'سنت هلنا، اسنشن و تریستان دا کونا',
      pl: 'Święta Helena',
      nl: 'Sint-Helena',
      tr: 'Saint Helena'
    },
    name: 'Saint Helena',
    phone_code: '290'
  },
  {
    code: 'KN',
    emoji: '🇰🇳',
    translations: {
      de: 'St. Kitts und Nevis',
      ru: 'Сент-Китс и Невис',
      pt: 'São Cristóvão e Neves',
      'pt-BR': 'São Cristóvão e Neves',
      kr: '세인트키츠 네비스',
      hr: 'Sveti Kristof i Nevis',
      it: 'Saint Kitts e Nevis',
      fr: 'Saint-Christophe-et-Niévès',
      'zh-cn': '圣基茨和尼维斯',
      es: 'San Cristóbal y Nieves',
      uk: 'Сент-Кітс і Невіс',
      ja: 'セントクリストファー・ネイビス',
      fa: 'سنت کیتس و نویس',
      pl: 'Saint Kitts i Nevis',
      nl: 'Saint Kitts en Nevis',
      tr: 'Saint Kitts Ve Nevis'
    },
    name: 'Saint Kitts and Nevis',
    phone_code: '1'
  },
  {
    code: 'LC',
    emoji: '🇱🇨',
    translations: {
      de: 'Saint Lucia',
      ru: 'Сент-Люсия',
      pt: 'Santa Lúcia',
      'pt-BR': 'Santa Lúcia',
      kr: '세인트루시아',
      hr: 'Sveta Lucija',
      it: 'Santa Lucia',
      fr: 'Saint-Lucie',
      'zh-cn': '圣卢西亚',
      es: 'Santa Lucía',
      uk: 'Сент-Люсія',
      ja: 'セントルシア',
      fa: 'سنت لوسیا',
      pl: 'Saint Lucia',
      nl: 'Saint Lucia',
      tr: 'Saint Lucia'
    },
    name: 'Saint Lucia',
    phone_code: '1'
  },
  {
    code: 'PM',
    emoji: '🇵🇲',
    translations: {
      de: 'Saint-Pierre und Miquelon',
      ru: 'Сен-Пьер и Микелон',
      pt: 'São Pedro e Miquelon',
      'pt-BR': 'Saint-Pierre e Miquelon',
      kr: '생피에르 미클롱',
      hr: 'Sveti Petar i Mikelon',
      it: 'Saint-Pierre e Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
      'zh-cn': '圣皮埃尔和密克隆',
      es: 'San Pedro y Miquelón',
      uk: "Сен-П'єр і Мікелон",
      ja: 'サンピエール島・ミクロン島',
      fa: 'سن پیر و میکلن',
      pl: 'Saint-Pierre i Miquelon',
      nl: 'Saint Pierre en Miquelon',
      tr: 'Saint Pierre Ve Miquelon'
    },
    name: 'Saint Pierre and Miquelon',
    phone_code: '508'
  },
  {
    code: 'VC',
    emoji: '🇻🇨',
    translations: {
      de: 'Saint Vincent und die Grenadinen',
      ru: 'Сент-Винсент и Гренадины',
      pt: 'São Vicente e Granadinas',
      'pt-BR': 'São Vicente e Granadinas',
      kr: '세인트빈센트 그레나딘',
      hr: 'Sveti Vincent i Grenadini',
      it: 'Saint Vincent e Grenadine',
      fr: 'Saint-Vincent-et-les-Grenadines',
      'zh-cn': '圣文森特和格林纳丁斯',
      es: 'San Vicente y Granadinas',
      uk: 'Сент-Вінсент і Гренадини',
      ja: 'セントビンセントおよびグレナディーン諸島',
      fa: 'سنت وینسنت و گرنادین‌ها',
      pl: 'Saint Vincent i Grenadyny',
      nl: 'Saint Vincent en de Grenadines',
      tr: 'Saint Vincent Ve Grenadinler'
    },
    name: 'Saint Vincent and the Grenadines',
    phone_code: '1'
  },
  {
    code: 'BL',
    emoji: '🇧🇱',
    translations: {
      de: 'Saint-Barthélemy',
      ru: 'Сен-Бартелеми',
      pt: 'São Bartolomeu',
      'pt-BR': 'São Bartolomeu',
      kr: '생바르텔레미',
      hr: 'Saint Barthélemy',
      it: 'Antille Francesi',
      fr: 'Saint-Barthélemy',
      'zh-cn': '圣巴泰勒米',
      es: 'San Bartolomé',
      uk: 'Сен-Бартелемі',
      ja: 'サン・バルテルミー',
      fa: 'سن-بارتلمی',
      pl: 'Saint-Barthelemy',
      nl: 'Saint Barthélemy',
      tr: 'Saint Barthélemy'
    },
    name: 'Saint-Barthelemy',
    phone_code: '590'
  },
  {
    code: 'MF',
    emoji: '🇲🇫',
    translations: {
      de: 'Saint Martin',
      ru: 'Сен-Мартен (французская часть)',
      pt: 'Ilha São Martinho',
      'pt-BR': 'Saint Martin',
      kr: '세인트마틴 섬',
      hr: 'Sveti Martin',
      it: 'Saint Martin',
      fr: 'Saint-Martin',
      'zh-cn': '密克罗尼西亚',
      es: 'Saint Martin',
      uk: 'Сен-Мартен (французька частина)',
      ja: 'サン・マルタン（フランス領）',
      fa: 'سینت مارتن',
      pl: 'Saint-Martin (część francuska)',
      nl: 'Saint-Martin',
      tr: 'Saint Martin'
    },
    name: 'Saint-Martin (French part)',
    phone_code: '590'
  },
  {
    code: 'WS',
    emoji: '🇼🇸',
    translations: {
      de: 'Samoa',
      ru: 'Самоа',
      pt: 'Samoa',
      'pt-BR': 'Samoa',
      kr: '사모아',
      hr: 'Samoa',
      it: 'Samoa',
      fr: 'Samoa',
      'zh-cn': '萨摩亚',
      es: 'Samoa',
      uk: 'Самоа',
      ja: 'サモア',
      fa: 'ساموآ',
      pl: 'Samoa',
      nl: 'Samoa',
      tr: 'Samoa'
    },
    name: 'Samoa',
    phone_code: '685'
  },
  {
    code: 'SM',
    emoji: '🇸🇲',
    translations: {
      de: 'San Marino',
      ru: 'Сан-Марино',
      pt: 'São Marinho',
      'pt-BR': 'San Marino',
      kr: '산마리노',
      hr: 'San Marino',
      it: 'San Marino',
      fr: 'Saint-Marin',
      'zh-cn': '圣马力诺',
      es: 'San Marino',
      uk: 'Сан-Марино',
      ja: 'サンマリノ',
      fa: 'سان مارینو',
      pl: 'San Marino',
      nl: 'San Marino',
      tr: 'San Marino'
    },
    name: 'San Marino',
    phone_code: '378'
  },
  {
    code: 'ST',
    emoji: '🇸🇹',
    translations: {
      de: 'São Tomé und Príncipe',
      ru: 'Сан-Томе и Принсипи',
      pt: 'São Tomé e Príncipe',
      'pt-BR': 'São Tomé e Príncipe',
      kr: '상투메 프린시페',
      hr: 'Sveti Toma i Princip',
      it: 'São Tomé e Príncipe',
      fr: 'Sao Tomé-et-Principe',
      'zh-cn': '圣多美和普林西比',
      es: 'Santo Tomé y Príncipe',
      uk: 'Сан-Томе і Принсіпі',
      ja: 'サントメ・プリンシペ',
      fa: 'کواترو دو فرویرو',
      pl: 'Wyspy Świętego Tomasza i Książęca',
      nl: 'Sao Tomé en Principe',
      tr: 'Sao Tome Ve Prinsipe'
    },
    name: 'Sao Tome and Principe',
    phone_code: '239'
  },
  {
    code: 'SA',
    emoji: '🇸🇦',
    translations: {
      de: 'Saudi-Arabien',
      ru: 'Саудовская Аравия',
      pt: 'Arábia Saudita',
      'pt-BR': 'Arábia Saudita',
      kr: '사우디아라비아',
      hr: 'Saudijska Arabija',
      it: 'Arabia Saudita',
      fr: 'Arabie Saoudite',
      'zh-cn': '沙特阿拉伯',
      es: 'Arabia Saudí',
      uk: 'Саудівська Аравія',
      ja: 'サウジアラビア',
      fa: 'عربستان سعودی',
      pl: 'Arabia Saudyjska',
      nl: 'Saoedi-Arabië',
      tr: 'Suudi Arabistan'
    },
    name: 'Saudi Arabia',
    phone_code: '966'
  },
  {
    code: 'SN',
    emoji: '🇸🇳',
    translations: {
      de: 'Senegal',
      ru: 'Сенегал',
      pt: 'Senegal',
      'pt-BR': 'Senegal',
      kr: '세네갈',
      hr: 'Senegal',
      it: 'Senegal',
      fr: 'Sénégal',
      'zh-cn': '塞内加尔',
      es: 'Senegal',
      uk: 'Сенегал',
      ja: 'セネガル',
      fa: 'سنگال',
      pl: 'Senegal',
      nl: 'Senegal',
      tr: 'Senegal'
    },
    name: 'Senegal',
    phone_code: '221'
  },
  {
    code: 'RS',
    emoji: '🇷🇸',
    translations: {
      de: 'Serbien',
      ru: 'Сербия',
      pt: 'Sérvia',
      'pt-BR': 'Sérvia',
      kr: '세르비아',
      hr: 'Srbija',
      it: 'Serbia',
      fr: 'Serbie',
      'zh-cn': '塞尔维亚',
      es: 'Serbia',
      uk: 'Сербія',
      ja: 'セルビア',
      fa: 'صربستان',
      pl: 'Serbia',
      nl: 'Servië',
      tr: 'Sirbistan'
    },
    name: 'Serbia',
    phone_code: '381'
  },
  {
    code: 'SC',
    emoji: '🇸🇨',
    translations: {
      de: 'Seychellen',
      ru: 'Сейшельские острова',
      pt: 'Seicheles',
      'pt-BR': 'Seicheles',
      kr: '세이셸',
      hr: 'Sejšeli',
      it: 'Seychelles',
      fr: 'Seychelles',
      'zh-cn': '塞舌尔',
      es: 'Seychelles',
      uk: 'Сейшельські острови',
      ja: 'セーシェル',
      fa: 'سیشل',
      pl: 'Seszele',
      nl: 'Seychellen',
      tr: 'Seyşeller'
    },
    name: 'Seychelles',
    phone_code: '248'
  },
  {
    code: 'SL',
    emoji: '🇸🇱',
    translations: {
      de: 'Sierra Leone',
      ru: 'Сьерра-Леоне',
      pt: 'Serra Leoa',
      'pt-BR': 'Serra Leoa',
      kr: '시에라리온',
      hr: 'Sijera Leone',
      it: 'Sierra Leone',
      fr: 'Sierra Leone',
      'zh-cn': '塞拉利昂',
      es: 'Sierra Leone',
      uk: 'Сьєрра-Леоне',
      ja: 'シエラレオネ',
      fa: 'سیرالئون',
      pl: 'Sierra Leone',
      nl: 'Sierra Leone',
      tr: 'Sierra Leone'
    },
    name: 'Sierra Leone',
    phone_code: '232'
  },
  {
    code: 'SG',
    emoji: '🇸🇬',
    translations: {
      de: 'Singapur',
      ru: 'Сингапур',
      pt: 'Singapura',
      'pt-BR': 'Singapura',
      kr: '싱가포르',
      hr: 'Singapur',
      it: 'Singapore',
      fr: 'Singapour',
      'zh-cn': '新加坡',
      es: 'Singapur',
      uk: 'Сінгапур',
      ja: 'シンガポール',
      fa: 'سنگاپور',
      pl: 'Singapur',
      nl: 'Singapore',
      tr: 'Singapur'
    },
    name: 'Singapore',
    phone_code: '65'
  },
  {
    code: 'SX',
    emoji: '🇸🇽',
    translations: {
      de: 'Sint Maarten (niederl. Teil)',
      ru: 'Синт-Мартен (голландская часть)',
      pt: 'São Martinho',
      'pt-BR': 'Sint Maarten',
      kr: '신트마르턴',
      it: 'Saint Martin (parte olandese)',
      fr: 'Saint Martin (partie néerlandaise)',
      'zh-cn': '圣马丁岛（荷兰部分）',
      uk: 'Сінт-Мартен (голландська частина)',
      fa: 'سینت مارتن',
      pl: 'Sint Maarten (część niderlandzka)',
      nl: 'Sint Maarten',
      tr: 'Sint Maarten'
    },
    name: 'Sint Maarten (Dutch part)',
    phone_code: '1721'
  },
  {
    code: 'SK',
    emoji: '🇸🇰',
    translations: {
      de: 'Slowakei',
      ru: 'Словакия',
      pt: 'Eslováquia',
      'pt-BR': 'Eslováquia',
      kr: '슬로바키아',
      hr: 'Slovačka',
      it: 'Slovacchia',
      fr: 'Slovaquie',
      'zh-cn': '斯洛伐克',
      es: 'República Eslovaca',
      uk: 'Словаччина',
      ja: 'スロバキア',
      fa: 'اسلواکی',
      pl: 'Słowacja',
      nl: 'Slowakije',
      tr: 'Slovakya'
    },
    name: 'Slovakia',
    phone_code: '421'
  },
  {
    code: 'SI',
    emoji: '🇸🇮',
    translations: {
      de: 'Slowenien',
      ru: 'Словения',
      pt: 'Eslovénia',
      'pt-BR': 'Eslovênia',
      kr: '슬로베니아',
      hr: 'Slovenija',
      it: 'Slovenia',
      fr: 'Slovénie',
      'zh-cn': '斯洛文尼亚',
      es: 'Eslovenia',
      uk: 'Словенія',
      ja: 'スロベニア',
      fa: 'اسلوونی',
      pl: 'Słowenia',
      nl: 'Slovenië',
      tr: 'Slovenya'
    },
    name: 'Slovenia',
    phone_code: '386'
  },
  {
    code: 'SB',
    emoji: '🇸🇧',
    translations: {
      de: 'Salomonen',
      ru: 'Соломоновы острова',
      pt: 'Ilhas Salomão',
      'pt-BR': 'Ilhas Salomão',
      kr: '솔로몬 제도',
      hr: 'Solomonski Otoci',
      it: 'Isole Salomone',
      fr: 'Îles Salomon',
      'zh-cn': '所罗门群岛',
      es: 'Islas Salomón',
      uk: 'Соломонові острови',
      ja: 'ソロモン諸島',
      fa: 'جزایر سلیمان',
      pl: 'Wyspy Salomona',
      nl: 'Salomonseilanden',
      tr: 'Solomon Adalari'
    },
    name: 'Solomon Islands',
    phone_code: '677'
  },
  {
    code: 'SO',
    emoji: '🇸🇴',
    translations: {
      de: 'Somalia',
      ru: 'Сомали',
      pt: 'Somália',
      'pt-BR': 'Somália',
      kr: '소말리아',
      hr: 'Somalija',
      it: 'Somalia',
      fr: 'Somalie',
      'zh-cn': '索马里',
      es: 'Somalia',
      uk: 'Сомалі',
      ja: 'ソマリア',
      fa: 'سومالی',
      pl: 'Somalia',
      nl: 'Somalië',
      tr: 'Somali'
    },
    name: 'Somalia',
    phone_code: '252'
  },
  {
    code: 'ZA',
    emoji: '🇿🇦',
    translations: {
      de: 'Republik Südafrika',
      ru: 'Южная Африка',
      pt: 'República Sul-Africana',
      'pt-BR': 'República Sul-Africana',
      kr: '남아프리카 공화국',
      hr: 'Južnoafrička Republika',
      it: 'Sud Africa',
      fr: 'Afrique du Sud',
      'zh-cn': '南非',
      es: 'República de Sudáfrica',
      uk: 'Південна Африка',
      ja: '南アフリカ',
      fa: 'آفریقای جنوبی',
      pl: 'Republika Południowej Afryki',
      nl: 'Zuid-Afrika',
      tr: 'Güney Afrika Cumhuriyeti'
    },
    name: 'South Africa',
    phone_code: '27'
  },
  {
    code: 'GS',
    emoji: '🇬🇸',
    translations: {
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      ru: 'Южная Джорджия',
      pt: 'Ilhas Geórgia do Sul e Sanduíche do Sul',
      'pt-BR': 'Ilhas Geórgias do Sul e Sandwich do Sul',
      kr: '사우스조지아',
      hr: 'Južna Georgija i otočje Južni Sandwich',
      it: 'Georgia del Sud e Isole Sandwich Meridionali',
      fr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
      'zh-cn': '南乔治亚',
      es: 'Islas Georgias del Sur y Sandwich del Sur',
      uk: 'Південна Джорджія',
      ja: 'サウスジョージア・サウスサンドウィッチ諸島',
      fa: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
      pl: 'Południowa Georgia',
      nl: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
      tr: 'Güney Georgia'
    },
    name: 'South Georgia',
    phone_code: '500'
  },
  {
    code: 'KR',
    emoji: '🇰🇷',
    translations: {
      de: 'Südkorea',
      ru: 'Южная Корея',
      pt: 'Coreia do Sul',
      'pt-BR': 'Coreia do Sul',
      kr: '대한민국',
      hr: 'Južna Koreja',
      it: 'Corea del Sud',
      fr: 'Corée du Sud',
      'zh-cn': '韩国',
      es: 'Corea del Sur',
      uk: 'Південна Корея',
      ja: '大韓民国',
      fa: 'کره شمالی',
      pl: 'Korea Południowa',
      nl: 'Zuid-Korea',
      tr: 'Güney Kore'
    },
    name: 'South Korea',
    phone_code: '82'
  },
  {
    code: 'SS',
    emoji: '🇸🇸',
    translations: {
      de: 'Südsudan',
      ru: 'Южный Судан',
      pt: 'Sudão do Sul',
      'pt-BR': 'Sudão do Sul',
      kr: '남수단',
      hr: 'Južni Sudan',
      it: 'Sudan del sud',
      fr: 'Soudan du Sud',
      'zh-cn': '南苏丹',
      es: 'Sudán del Sur',
      uk: 'Південний Судан',
      ja: '南スーダン',
      fa: 'سودان جنوبی',
      pl: 'Sudan Południowy',
      nl: 'Zuid-Soedan',
      tr: 'Güney Sudan'
    },
    name: 'South Sudan',
    phone_code: '211'
  },
  {
    code: 'ES',
    emoji: '🇪🇸',
    translations: {
      de: 'Spanien',
      ru: 'Испания',
      pt: 'Espanha',
      'pt-BR': 'Espanha',
      kr: '스페인',
      hr: 'Španjolska',
      it: 'Spagna',
      fr: 'Espagne',
      'zh-cn': '西班牙',
      es: 'España',
      uk: 'Іспанія',
      ja: 'スペイン',
      fa: 'اسپانیا',
      pl: 'Hiszpania',
      nl: 'Spanje',
      tr: 'İspanya'
    },
    name: 'Spain',
    phone_code: '34'
  },
  {
    code: 'LK',
    emoji: '🇱🇰',
    translations: {
      de: 'Sri Lanka',
      ru: 'Шри-Ланка',
      pt: 'Sri Lanka',
      'pt-BR': 'Sri Lanka',
      kr: '스리랑카',
      hr: 'Šri Lanka',
      it: 'Sri Lanka',
      fr: 'Sri Lanka',
      'zh-cn': '斯里兰卡',
      es: 'Sri Lanka',
      uk: 'Шрі-Ланка',
      ja: 'スリランカ',
      fa: 'سری‌لانکا',
      pl: 'Sri Lanka',
      nl: 'Sri Lanka',
      tr: 'Sri Lanka'
    },
    name: 'Sri Lanka',
    phone_code: '94'
  },
  {
    code: 'SD',
    emoji: '🇸🇩',
    translations: {
      de: 'Sudan',
      ru: 'Судан',
      pt: 'Sudão',
      'pt-BR': 'Sudão',
      kr: '수단',
      hr: 'Sudan',
      it: 'Sudan',
      fr: 'Soudan',
      'zh-cn': '苏丹',
      es: 'Sudán',
      uk: 'Судан',
      ja: 'スーダン',
      fa: 'سودان',
      pl: 'Sudan',
      nl: 'Soedan',
      tr: 'Sudan'
    },
    name: 'Sudan',
    phone_code: '249'
  },
  {
    code: 'SR',
    emoji: '🇸🇷',
    translations: {
      de: 'Suriname',
      ru: 'Суринам',
      pt: 'Suriname',
      'pt-BR': 'Suriname',
      kr: '수리남',
      hr: 'Surinam',
      it: 'Suriname',
      fr: 'Surinam',
      'zh-cn': '苏里南',
      es: 'Surinam',
      uk: 'Суринам',
      ja: 'スリナム',
      fa: 'سورینام',
      pl: 'Surinam',
      nl: 'Suriname',
      tr: 'Surinam'
    },
    name: 'Suriname',
    phone_code: '597'
  },
  {
    code: 'SJ',
    emoji: '🇸🇯',
    translations: {
      de: 'Svalbard und Jan Mayen',
      ru: 'Шпицберген и острова Ян-Майен',
      pt: 'Svalbard',
      'pt-BR': 'Svalbard',
      kr: '스발바르 얀마옌 제도',
      hr: 'Svalbard i Jan Mayen',
      it: 'Svalbard e Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      'zh-cn': '斯瓦尔巴和扬马延群岛',
      es: 'Islas Svalbard y Jan Mayen',
      uk: 'Шпіцберген та острови Ян-Майєн',
      ja: 'スヴァールバル諸島およびヤンマイエン島',
      fa: 'سوالبارد و یان ماین',
      pl: 'Wyspy Svalbard i Jan Mayen',
      nl: 'Svalbard en Jan Mayen',
      tr: 'Svalbard Ve Jan Mayen'
    },
    name: 'Svalbard and Jan Mayen Islands',
    phone_code: '47'
  },
  {
    code: 'SE',
    emoji: '🇸🇪',
    translations: {
      de: 'Schweden',
      ru: 'Швеция',
      pt: 'Suécia',
      'pt-BR': 'Suécia',
      kr: '스웨덴',
      hr: 'Švedska',
      it: 'Svezia',
      fr: 'Suède',
      'zh-cn': '瑞典',
      es: 'Suecia',
      uk: 'Швеція',
      ja: 'スウェーデン',
      fa: 'سوئد',
      pl: 'Szwecja',
      nl: 'Zweden',
      tr: 'İsveç'
    },
    name: 'Sweden',
    phone_code: '46'
  },
  {
    code: 'CH',
    emoji: '🇨🇭',
    translations: {
      de: 'Schweiz',
      ru: 'Швейцария',
      pt: 'Suíça',
      'pt-BR': 'Suíça',
      kr: '스위스',
      hr: 'Švicarska',
      it: 'Svizzera',
      fr: 'Suisse',
      'zh-cn': '瑞士',
      es: 'Suiza',
      uk: 'Швейцарія',
      ja: 'スイス',
      fa: 'سوئیس',
      pl: 'Szwajcaria',
      nl: 'Zwitserland',
      tr: 'İsviçre'
    },
    name: 'Switzerland',
    phone_code: '41'
  },
  {
    code: 'SY',
    emoji: '🇸🇾',
    translations: {
      de: 'Syrien',
      ru: 'Сирия',
      pt: 'Síria',
      'pt-BR': 'Síria',
      kr: '시리아',
      hr: 'Sirija',
      it: 'Siria',
      fr: 'Syrie',
      'zh-cn': '叙利亚',
      es: 'Siria',
      uk: 'Сирія',
      ja: 'シリア・アラブ共和国',
      fa: 'سوریه',
      pl: 'Syria',
      nl: 'Syrië',
      tr: 'Suriye'
    },
    name: 'Syria',
    phone_code: '963'
  },
  {
    code: 'TW',
    emoji: '🇹🇼',
    translations: {
      de: 'Taiwan',
      ru: 'Тайвань',
      pt: 'Taiwan',
      'pt-BR': 'Taiwan',
      kr: '대만',
      hr: 'Tajvan',
      it: 'Taiwan',
      fr: 'Taïwan',
      'zh-cn': '中国台湾',
      es: 'Taiwán',
      uk: 'Тайвань',
      ja: '台湾（中華民国）',
      fa: 'تایوان',
      pl: 'Tajwan',
      nl: 'Taiwan',
      tr: 'Tayvan'
    },
    name: 'Taiwan',
    phone_code: '886'
  },
  {
    code: 'TJ',
    emoji: '🇹🇯',
    translations: {
      de: 'Tadschikistan',
      ru: 'Таджикистан',
      pt: 'Tajiquistão',
      'pt-BR': 'Tajiquistão',
      kr: '타지키스탄',
      hr: 'Tađikistan',
      it: 'Tagikistan',
      fr: 'Tadjikistan',
      'zh-cn': '塔吉克斯坦',
      es: 'Tayikistán',
      uk: 'Таджикистан',
      ja: 'タジキスタン',
      fa: 'تاجیکستان',
      pl: 'Tadżykistan',
      nl: 'Tadzjikistan',
      tr: 'Tacikistan'
    },
    name: 'Tajikistan',
    phone_code: '992'
  },
  {
    code: 'TZ',
    emoji: '🇹🇿',
    translations: {
      de: 'Tansania',
      ru: 'Танзания',
      pt: 'Tanzânia',
      'pt-BR': 'Tanzânia',
      kr: '탄자니아',
      hr: 'Tanzanija',
      it: 'Tanzania',
      fr: 'Tanzanie',
      'zh-cn': '坦桑尼亚',
      es: 'Tanzania',
      uk: 'Танзанія',
      ja: 'タンザニア',
      fa: 'تانزانیا',
      pl: 'Tanzania',
      nl: 'Tanzania',
      tr: 'Tanzanya'
    },
    name: 'Tanzania',
    phone_code: '255'
  },
  {
    code: 'TH',
    emoji: '🇹🇭',
    translations: {
      de: 'Thailand',
      ru: 'Таиланд',
      pt: 'Tailândia',
      'pt-BR': 'Tailândia',
      kr: '태국',
      hr: 'Tajland',
      it: 'Tailandia',
      fr: 'Thaïlande',
      'zh-cn': '泰国',
      es: 'Tailandia',
      uk: 'Таїланд',
      ja: 'タイ',
      fa: 'تایلند',
      pl: 'Tajlandia',
      nl: 'Thailand',
      tr: 'Tayland'
    },
    name: 'Thailand',
    phone_code: '66'
  },
  {
    code: 'BS',
    emoji: '🇧🇸',
    translations: {
      de: 'Bahamas',
      ru: 'Багамы',
      pt: 'Baamas',
      'pt-BR': 'Bahamas',
      kr: '바하마',
      hr: 'Bahami',
      it: 'Bahamas',
      fr: 'Bahamas',
      'zh-cn': '巴哈马',
      es: 'Bahamas',
      uk: 'Багамські острови',
      ja: 'バハマ',
      fa: 'باهاما',
      pl: 'Bahamy',
      nl: 'Bahama’s',
      tr: 'Bahamalar'
    },
    name: 'The Bahamas',
    phone_code: '1'
  },
  {
    code: 'GM',
    emoji: '🇬🇲',
    translations: {
      de: 'Gambia',
      ru: 'Гамбия',
      pt: 'Gâmbia',
      'pt-BR': 'Gâmbia',
      kr: '감비아',
      hr: 'Gambija',
      it: 'Gambia',
      fr: 'Gambie',
      'zh-cn': '冈比亚',
      es: 'Gambia',
      uk: 'Гамбія The',
      ja: 'ガンビア',
      fa: 'گامبیا',
      pl: 'Gambia The',
      nl: 'Gambia',
      tr: 'Gambiya'
    },
    name: 'The Gambia ',
    phone_code: '220'
  },
  {
    code: 'TL',
    emoji: '🇹🇱',
    translations: {
      de: 'Timor-Leste',
      ru: 'Тимор-Лешти',
      pt: 'Timor Leste',
      'pt-BR': 'Timor Leste',
      kr: '동티모르',
      hr: 'Istočni Timor',
      it: 'Timor Est',
      fr: 'Timor oriental',
      'zh-cn': '东帝汶',
      es: 'Timor Oriental',
      uk: 'Тимор-Лешті',
      ja: '東ティモール',
      fa: 'تیمور شرقی',
      pl: 'Timor Wschodni',
      nl: 'Oost-Timor',
      tr: 'Doğu Timor'
    },
    name: 'Timor-Leste',
    phone_code: '670'
  },
  {
    code: 'TG',
    emoji: '🇹🇬',
    translations: {
      de: 'Togo',
      ru: 'Того',
      pt: 'Togo',
      'pt-BR': 'Togo',
      kr: '토고',
      hr: 'Togo',
      it: 'Togo',
      fr: 'Togo',
      'zh-cn': '多哥',
      es: 'Togo',
      uk: 'Того',
      ja: 'トーゴ',
      fa: 'توگو',
      pl: 'Togo',
      nl: 'Togo',
      tr: 'Togo'
    },
    name: 'Togo',
    phone_code: '228'
  },
  {
    code: 'TK',
    emoji: '🇹🇰',
    translations: {
      de: 'Tokelau',
      ru: 'Токелау',
      pt: 'Toquelau',
      'pt-BR': 'Tokelau',
      kr: '토켈라우',
      hr: 'Tokelau',
      it: 'Isole Tokelau',
      fr: 'Tokelau',
      'zh-cn': '托克劳',
      es: 'Islas Tokelau',
      uk: 'Токелау',
      ja: 'トケラウ',
      fa: 'توکلائو',
      pl: 'Tokelau',
      nl: 'Tokelau',
      tr: 'Tokelau'
    },
    name: 'Tokelau',
    phone_code: '690'
  },
  {
    code: 'TO',
    emoji: '🇹🇴',
    translations: {
      de: 'Tonga',
      ru: 'Тонга',
      pt: 'Tonga',
      'pt-BR': 'Tonga',
      kr: '통가',
      hr: 'Tonga',
      it: 'Tonga',
      fr: 'Tonga',
      'zh-cn': '汤加',
      es: 'Tonga',
      uk: 'Тонга',
      ja: 'トンガ',
      fa: 'تونگا',
      pl: 'Tonga',
      nl: 'Tonga',
      tr: 'Tonga'
    },
    name: 'Tonga',
    phone_code: '676'
  },
  {
    code: 'TT',
    emoji: '🇹🇹',
    translations: {
      de: 'Trinidad und Tobago',
      ru: 'Тринидад и Тобаго',
      pt: 'Trindade e Tobago',
      'pt-BR': 'Trinidad e Tobago',
      kr: '트리니다드 토바고',
      hr: 'Trinidad i Tobago',
      it: 'Trinidad e Tobago',
      fr: 'Trinité et Tobago',
      'zh-cn': '特立尼达和多巴哥',
      es: 'Trinidad y Tobago',
      uk: 'Тринідад і Тобаго',
      ja: 'トリニダード・トバゴ',
      fa: 'ترینیداد و توباگو',
      pl: 'Trynidad i Tobago',
      nl: 'Trinidad en Tobago',
      tr: 'Trinidad Ve Tobago'
    },
    name: 'Trinidad and Tobago',
    phone_code: '1'
  },
  {
    code: 'TN',
    emoji: '🇹🇳',
    translations: {
      de: 'Tunesien',
      ru: 'Тунис',
      pt: 'Tunísia',
      'pt-BR': 'Tunísia',
      kr: '튀니지',
      hr: 'Tunis',
      it: 'Tunisia',
      fr: 'Tunisie',
      'zh-cn': '突尼斯',
      es: 'Túnez',
      uk: 'Туніс',
      ja: 'チュニジア',
      fa: 'تونس',
      pl: 'Tunezja',
      nl: 'Tunesië',
      tr: 'Tunus'
    },
    name: 'Tunisia',
    phone_code: '216'
  },
  {
    code: 'TR',
    emoji: '🇹🇷',
    translations: {
      de: 'Türkei',
      ru: 'Турция',
      pt: 'Turquia',
      'pt-BR': 'Turquia',
      kr: '터키',
      hr: 'Turska',
      it: 'Turchia',
      fr: 'Turquie',
      'zh-cn': '土耳其',
      es: 'Turquía',
      uk: 'Туреччина',
      ja: 'トルコ',
      fa: 'ترکیه',
      pl: 'Turcja',
      nl: 'Turkije',
      tr: 'Türkiye'
    },
    name: 'Turkey',
    phone_code: '90'
  },
  {
    code: 'TM',
    emoji: '🇹🇲',
    translations: {
      de: 'Turkmenistan',
      ru: 'Туркменистан',
      pt: 'Turquemenistão',
      'pt-BR': 'Turcomenistão',
      kr: '투르크메니스탄',
      hr: 'Turkmenistan',
      it: 'Turkmenistan',
      fr: 'Turkménistan',
      'zh-cn': '土库曼斯坦',
      es: 'Turkmenistán',
      uk: 'Туркменістан',
      ja: 'トルクメニスタン',
      fa: 'ترکمنستان',
      pl: 'Turkmenistan',
      nl: 'Turkmenistan',
      tr: 'Türkmenistan'
    },
    name: 'Turkmenistan',
    phone_code: '993'
  },
  {
    code: 'TC',
    emoji: '🇹🇨',
    translations: {
      de: 'Turks- und Caicosinseln',
      ru: 'Острова Теркс и Кайкос',
      pt: 'Ilhas Turcas e Caicos',
      'pt-BR': 'Ilhas Turcas e Caicos',
      kr: '터크스 케이커스 제도',
      hr: 'Otoci Turks i Caicos',
      it: 'Isole Turks e Caicos',
      fr: 'Îles Turques-et-Caïques',
      'zh-cn': '特克斯和凯科斯群岛',
      es: 'Islas Turks y Caicos',
      uk: 'Острови Теркс і Кайкос',
      ja: 'タークス・カイコス諸島',
      fa: 'جزایر تورکس و کایکوس',
      pl: 'Wyspy Turks i Caicos',
      nl: 'Turks- en Caicoseilanden',
      tr: 'Turks Ve Caicos Adalari'
    },
    name: 'Turks and Caicos Islands',
    phone_code: '1'
  },
  {
    code: 'TV',
    emoji: '🇹🇻',
    translations: {
      de: 'Tuvalu',
      ru: 'Тувалу',
      pt: 'Tuvalu',
      'pt-BR': 'Tuvalu',
      kr: '투발루',
      hr: 'Tuvalu',
      it: 'Tuvalu',
      fr: 'Tuvalu',
      'zh-cn': '图瓦卢',
      es: 'Tuvalu',
      uk: 'Тувалу',
      ja: 'ツバル',
      fa: 'تووالو',
      pl: 'Tuvalu',
      nl: 'Tuvalu',
      tr: 'Tuvalu'
    },
    name: 'Tuvalu',
    phone_code: '688'
  },
  {
    code: 'UG',
    emoji: '🇺🇬',
    translations: {
      de: 'Uganda',
      ru: 'Уганда',
      pt: 'Uganda',
      'pt-BR': 'Uganda',
      kr: '우간다',
      hr: 'Uganda',
      it: 'Uganda',
      fr: 'Uganda',
      'zh-cn': '乌干达',
      es: 'Uganda',
      uk: 'Уганда',
      ja: 'ウガンダ',
      fa: 'اوگاندا',
      pl: 'Uganda',
      nl: 'Oeganda',
      tr: 'Uganda'
    },
    name: 'Uganda',
    phone_code: '256'
  },
  {
    code: 'UA',
    emoji: '🇺🇦',
    translations: {
      de: 'Ukraine',
      ru: 'Украина',
      pt: 'Ucrânia',
      'pt-BR': 'Ucrânia',
      kr: '우크라이나',
      hr: 'Ukrajina',
      it: 'Ucraina',
      fr: 'Ukraine',
      'zh-cn': '乌克兰',
      es: 'Ucrania',
      uk: 'Україна',
      ja: 'ウクライナ',
      fa: 'وکراین',
      pl: 'Ukraina',
      nl: 'Oekraïne',
      tr: 'Ukrayna'
    },
    name: 'Ukraine',
    phone_code: '380'
  },
  {
    code: 'AE',
    emoji: '🇦🇪',
    translations: {
      de: 'Vereinigte Arabische Emirate',
      ru: 'Объединенные Арабские Эмираты',
      pt: 'Emirados árabes Unidos',
      'pt-BR': 'Emirados árabes Unidos',
      kr: '아랍에미리트',
      hr: 'Ujedinjeni Arapski Emirati',
      it: 'Emirati Arabi Uniti',
      fr: 'Émirats arabes unis',
      'zh-cn': '阿拉伯联合酋长国',
      es: 'Emiratos Árabes Unidos',
      uk: "Об'єднані Арабські Емірати",
      ja: 'アラブ首長国連邦',
      fa: 'امارات متحده عربی',
      pl: 'Zjednoczone Emiraty Arabskie',
      nl: 'Verenigde Arabische Emiraten',
      tr: 'Birleşik Arap Emirlikleri'
    },
    name: 'United Arab Emirates',
    phone_code: '971'
  },
  {
    code: 'GB',
    emoji: '🇬🇧',
    translations: {
      de: 'Vereinigtes Königreich',
      ru: 'Великобритания',
      pt: 'Reino Unido',
      'pt-BR': 'Reino Unido',
      kr: '영국',
      hr: 'Ujedinjeno Kraljevstvo',
      it: 'Regno Unito',
      fr: 'Royaume-Uni',
      'zh-cn': '英国',
      es: 'Reino Unido',
      uk: 'Сполучене Королівство',
      ja: 'イギリス',
      fa: 'بریتانیای کبیر و ایرلند شمالی',
      pl: 'Wielka Brytania',
      nl: 'Verenigd Koninkrijk',
      tr: 'Birleşik Krallik'
    },
    name: 'United Kingdom',
    phone_code: '44'
  },
  {
    code: 'US',
    emoji: '🇺🇸',
    translations: {
      de: 'Vereinigte Staaten von Amerika',
      ru: 'Соединенные Штаты',
      pt: 'Estados Unidos',
      'pt-BR': 'Estados Unidos',
      kr: '미국',
      hr: 'Sjedinjene Američke Države',
      it: "Stati Uniti D'America",
      fr: 'États-Unis',
      'zh-cn': '美国',
      es: 'Estados Unidos',
      uk: 'Сполучені Штати',
      ja: 'アメリカ合衆国',
      fa: 'ایالات متحده آمریکا',
      pl: 'Stany Zjednoczone',
      nl: 'Verenigde Staten',
      tr: 'Amerika'
    },
    name: 'United States',
    phone_code: '1'
  },
  {
    code: 'UM',
    emoji: '🇺🇲',
    translations: {
      de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      ru: 'Малые отдаленные острова Соединенных Штатов',
      pt: 'Ilhas Menores Distantes dos Estados Unidos',
      'pt-BR': 'Ilhas Menores Distantes dos Estados Unidos',
      kr: '미국령 군소 제도',
      hr: 'Mali udaljeni otoci SAD-a',
      it: "Isole minori esterne degli Stati Uniti d'America",
      fr: 'Îles mineures éloignées des États-Unis',
      'zh-cn': '美国本土外小岛屿',
      es: 'Islas Ultramarinas Menores de Estados Unidos',
      uk: 'Малі віддалені острови Сполучених Штатів',
      ja: '合衆国領有小離島',
      fa: 'جزایر کوچک حاشیه‌ای ایالات متحده آمریکا',
      pl: 'Mniejsze Wyspy Zewnętrzne Stanów Zjednoczonych',
      nl: 'Kleine afgelegen eilanden van de Verenigde Staten',
      tr: 'Abd Küçük Harici Adalari'
    },
    name: 'United States Minor Outlying Islands',
    phone_code: '1'
  },
  {
    code: 'UY',
    emoji: '🇺🇾',
    translations: {
      de: 'Uruguay',
      ru: 'Уругвай',
      pt: 'Uruguai',
      'pt-BR': 'Uruguai',
      kr: '우루과이',
      hr: 'Urugvaj',
      it: 'Uruguay',
      fr: 'Uruguay',
      'zh-cn': '乌拉圭',
      es: 'Uruguay',
      uk: 'Уругвай',
      ja: 'ウルグアイ',
      fa: 'اروگوئه',
      pl: 'Urugwaj',
      nl: 'Uruguay',
      tr: 'Uruguay'
    },
    name: 'Uruguay',
    phone_code: '598'
  },
  {
    code: 'UZ',
    emoji: '🇺🇿',
    translations: {
      de: 'Usbekistan',
      ru: 'Узбекистан',
      pt: 'Usbequistão',
      'pt-BR': 'Uzbequistão',
      kr: '우즈베키스탄',
      hr: 'Uzbekistan',
      it: 'Uzbekistan',
      fr: 'Ouzbékistan',
      'zh-cn': '乌兹别克斯坦',
      es: 'Uzbekistán',
      uk: 'Узбекистан',
      ja: 'ウズベキスタン',
      fa: 'ازبکستان',
      pl: 'Uzbekistan',
      nl: 'Oezbekistan',
      tr: 'Özbekistan'
    },
    name: 'Uzbekistan',
    phone_code: '998'
  },
  {
    code: 'VU',
    emoji: '🇻🇺',
    translations: {
      de: 'Vanuatu',
      ru: 'Вануату',
      pt: 'Vanuatu',
      'pt-BR': 'Vanuatu',
      kr: '바누아투',
      hr: 'Vanuatu',
      it: 'Vanuatu',
      fr: 'Vanuatu',
      'zh-cn': '瓦努阿图',
      es: 'Vanuatu',
      uk: 'Вануату',
      ja: 'バヌアツ',
      fa: 'وانواتو',
      pl: 'Vanuatu',
      nl: 'Vanuatu',
      tr: 'Vanuatu'
    },
    name: 'Vanuatu',
    phone_code: '678'
  },
  {
    code: 'VA',
    emoji: '🇻🇦',
    translations: {
      de: 'Heiliger Stuhl',
      ru: 'Город-государство Ватикан (Святой Престол)',
      pt: 'Vaticano',
      'pt-BR': 'Vaticano',
      kr: '바티칸 시국',
      hr: 'Sveta Stolica',
      it: 'Santa Sede',
      fr: 'voir Saint',
      'zh-cn': '梵蒂冈',
      es: 'Santa Sede',
      uk: 'Держава-місто Ватикан (Святий Престол)',
      ja: '聖座',
      fa: 'سریر مقدس',
      pl: 'Państwo Watykańskie (Stolica Apostolska)',
      nl: 'Heilige Stoel',
      tr: 'Vatikan'
    },
    name: 'Vatican City State (Holy See)',
    phone_code: '379'
  },
  {
    code: 'VE',
    emoji: '🇻🇪',
    translations: {
      de: 'Venezuela',
      ru: 'Венесуэла',
      pt: 'Venezuela',
      'pt-BR': 'Venezuela',
      kr: '베네수엘라',
      hr: 'Venezuela',
      it: 'Venezuela',
      fr: 'Venezuela',
      'zh-cn': '委内瑞拉',
      es: 'Venezuela',
      uk: 'Венесуела',
      ja: 'ベネズエラ・ボリバル共和国',
      fa: 'ونزوئلا',
      pl: 'Wenezuela',
      nl: 'Venezuela',
      tr: 'Venezuela'
    },
    name: 'Venezuela',
    phone_code: '58'
  },
  {
    code: 'VN',
    emoji: '🇻🇳',
    translations: {
      de: 'Vietnam',
      ru: 'Вьетнам',
      pt: 'Vietname',
      'pt-BR': 'Vietnã',
      kr: '베트남',
      hr: 'Vijetnam',
      it: 'Vietnam',
      fr: 'Viêt Nam',
      'zh-cn': '越南',
      es: 'Vietnam',
      uk: "В'єтнам",
      ja: 'ベトナム',
      fa: 'ویتنام',
      pl: 'Wietnam',
      nl: 'Vietnam',
      tr: 'Vietnam'
    },
    name: 'Vietnam',
    phone_code: '84'
  },
  {
    code: 'VG',
    emoji: '🇻🇬',
    translations: {
      de: 'Britische Jungferninseln',
      ru: 'Виргинские острова (Британские)',
      pt: 'Ilhas Virgens Britânicas',
      'pt-BR': 'Ilhas Virgens Britânicas',
      kr: '영국령 버진아일랜드',
      hr: 'Britanski Djevičanski Otoci',
      it: 'Isole Vergini Britanniche',
      fr: 'Îles Vierges britanniques',
      'zh-cn': '圣文森特和格林纳丁斯',
      es: 'Islas Vírgenes del Reino Unido',
      uk: 'Віргінські острови (Британські)',
      ja: 'イギリス領ヴァージン諸島',
      fa: 'جزایر ویرجین بریتانیا',
      pl: 'Wyspy Dziewicze (Brytyjskie)',
      nl: 'Britse Maagdeneilanden',
      tr: 'Britanya Virjin Adalari'
    },
    name: 'Virgin Islands (British)',
    phone_code: '1'
  },
  {
    code: 'VI',
    emoji: '🇻🇮',
    translations: {
      de: 'Amerikanische Jungferninseln',
      ru: 'Виргинские острова (США)',
      pt: 'Ilhas Virgens Americanas',
      'pt-BR': 'Ilhas Virgens Americanas',
      kr: '미국령 버진아일랜드',
      it: 'Isole Vergini americane',
      fr: 'Îles Vierges des États-Unis',
      'zh-cn': '维尔京群岛（美国）',
      es: 'Islas Vírgenes de los Estados Unidos',
      uk: 'Віргінські острови (США)',
      ja: 'アメリカ領ヴァージン諸島',
      fa: 'جزایر ویرجین آمریکا',
      pl: 'Wyspy Dziewicze (USA)',
      nl: 'Verenigde Staten Maagdeneilanden',
      tr: 'Abd Virjin Adalari'
    },
    name: 'Virgin Islands (US)',
    phone_code: '1'
  },
  {
    code: 'WF',
    emoji: '🇼🇫',
    translations: {
      de: 'Wallis und Futuna',
      ru: 'Острова Уоллис и Футуна',
      pt: 'Wallis e Futuna',
      'pt-BR': 'Wallis e Futuna',
      kr: '왈리스 푸투나',
      hr: 'Wallis i Fortuna',
      it: 'Wallis e Futuna',
      fr: 'Wallis-et-Futuna',
      'zh-cn': '瓦利斯群岛和富图纳群岛',
      es: 'Wallis y Futuna',
      uk: 'Острови Уолліс і Футуна',
      ja: 'ウォリス・フツナ',
      fa: 'والیس و فوتونا',
      pl: 'Wyspy Wallis i Futuna',
      nl: 'Wallis en Futuna',
      tr: 'Wallis Ve Futuna'
    },
    name: 'Wallis and Futuna Islands',
    phone_code: '681'
  },
  {
    code: 'EH',
    emoji: '🇪🇭',
    translations: {
      de: 'Westsahara',
      ru: 'Западная Сахара',
      pt: 'Saara Ocidental',
      'pt-BR': 'Saara Ocidental',
      kr: '서사하라',
      hr: 'Zapadna Sahara',
      it: 'Sahara Occidentale',
      fr: 'Sahara Occidental',
      'zh-cn': '西撒哈拉',
      es: 'Sahara Occidental',
      uk: 'Західна Сахара',
      ja: '西サハラ',
      fa: 'جمهوری دموکراتیک عربی صحرا',
      pl: 'Sahara Zachodnia',
      nl: 'Westelijke Sahara',
      tr: 'Bati Sahra'
    },
    name: 'Western Sahara',
    phone_code: '212'
  },
  {
    code: 'YE',
    emoji: '🇾🇪',
    translations: {
      de: 'Jemen',
      ru: 'Йемен',
      pt: 'Iémen',
      'pt-BR': 'Iêmen',
      kr: '예멘',
      hr: 'Jemen',
      it: 'Yemen',
      fr: 'Yémen',
      'zh-cn': '也门',
      es: 'Yemen',
      uk: 'Ємен',
      ja: 'イエメン',
      fa: 'یمن',
      pl: 'Jemen',
      nl: 'Jemen',
      tr: 'Yemen'
    },
    name: 'Yemen',
    phone_code: '967'
  },
  {
    code: 'ZM',
    emoji: '🇿🇲',
    translations: {
      de: 'Sambia',
      ru: 'Замбия',
      pt: 'Zâmbia',
      'pt-BR': 'Zâmbia',
      kr: '잠비아',
      hr: 'Zambija',
      it: 'Zambia',
      fr: 'Zambie',
      'zh-cn': '赞比亚',
      es: 'Zambia',
      uk: 'Замбія',
      ja: 'ザンビア',
      fa: 'زامبیا',
      pl: 'Zambia',
      nl: 'Zambia',
      tr: 'Zambiya'
    },
    name: 'Zambia',
    phone_code: '260'
  },
  {
    code: 'ZW',
    emoji: '🇿🇼',
    translations: {
      de: 'Simbabwe',
      ru: 'Зимбабве',
      pt: 'Zimbabué',
      'pt-BR': 'Zimbabwe',
      kr: '짐바브웨',
      hr: 'Zimbabve',
      it: 'Zimbabwe',
      fr: 'Zimbabwe',
      'zh-cn': '津巴布韦',
      es: 'Zimbabue',
      uk: 'Зімбабве',
      ja: 'ジンバブエ',
      fa: 'زیمباوه',
      pl: 'Zimbabwe',
      nl: 'Zimbabwe',
      tr: 'Zimbabve'
    },
    name: 'Zimbabwe',
    phone_code: '263'
  }
]
